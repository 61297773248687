import React from "react";
import ReactMarkdown from "react-markdown";
import { Theme, createStyles, lighten } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import remarkGfm from 'remark-gfm'

import { Grid, Paper, Container } from "@mui/material";

import { WrappedComponentProps } from "../utils/authHelper";
import remarktoc from "remark-toc";
import slug from "rehype-slug";

import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
  },
  container: {
    width: "100%",
    position: "relative",
  },
  card: {},
  table: {
    padding: theme.spacing(2),
    width: "90%",
  },
  diagram: {
    marginTop: 50,
    padding: 30,
  },
  img: {
    widh: "250px",
    borderRadius: "8px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

interface IEventsPageProps {}

export const AppsInfoPage: React.FunctionComponent<IEventsPageProps> = (
  props
) => {
  const { classes } = useStyles();
  const [markdown, setMarkdown] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  //     public componentDidMount() {
  //     import(`../docs/Handelsapp.md`)
  //         .then(res => {
  //             fetch(res.default)
  //                 .then(res => res.text())
  //                 .then(res => this.setState({ markdown: res }))
  //                 .catch(err => console.log(err));
  //         })
  //         .catch(err => console.log(err));
  // }

  React.useEffect(() => {
    if (markdown === "") {
      setLoading(true);
      import("../docs/AppsInfo.md")
        .then((res) => {
          fetch(res.default)
            .then((res) => res.text())
            .then((res) => {
              setMarkdown(res);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  });

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        {loading && <CircularProgress color="primary" />}
        <ReactMarkdown
          className={classes.table}
          children={markdown}
          remarkPlugins={[remarktoc, remarkGfm]}
          rehypePlugins={[slug]}
        />
      </Grid>
    </Grid>
  );
};
