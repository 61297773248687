import React from "react";

interface IIconProps {
  className?: string;
  style?: React.CSSProperties;
}

interface ICustomIconProps extends IIconProps {
  path: string;
  style?: React.CSSProperties;
}

export const CustomIcon = (props: ICustomIconProps) => {
  const { path, ...rest } = props;
  return <img src={props.path} alt={"icon"} {...rest} />;
};

export const DuplicateIcon = (props: IIconProps) => <CustomIcon path={"/svg/duplicate-icon.svg"} {...props} />;
export const DuplicateInactiveIcon = (props: IIconProps) => (
  <CustomIcon path={"/svg/duplicate-icon-inactive.svg"} {...props} />
);
export const ExchangeIcon = (props: IIconProps) => <CustomIcon path={"/svg/exchange-icon.svg"} {...props} />;
export const ExchangeInactiveIcon = (props: IIconProps) => (
  <CustomIcon path={"/svg/exchange-icon-inactive.svg"} {...props} />
);
export const PickupIcon = (props: IIconProps) => <CustomIcon path={"/svg/pickup-icon.svg"} {...props} />;
export const PickupInactiveIcon = (props: IIconProps) => (
  <CustomIcon path={"/svg/pickup-icon-inactive.svg"} {...props} />
);
export const ProblemIcon = (props: IIconProps) => <CustomIcon path={"/svg/problem-icon.svg"} {...props} />;
export const ArrowUpIcon = (props: IIconProps) => <CustomIcon path={"/svg/arrow_up.svg"} {...props} />;
export const ArrowDownIcon = (props: IIconProps) => <CustomIcon path={"/svg/arrow_down.svg"} {...props} />;
export const ArrowUpIconWhite = (props: IIconProps) => <CustomIcon path={"/svg/arrow_up_white.svg"} {...props} />;
export const ArrowDownIconWhite = (props: IIconProps) => <CustomIcon path={"/svg/arrow_down_white.svg"} {...props} />;

export const AddContainerIcon = (props: IIconProps) => (
  <CustomIcon path={"/svg/navigation_add_container.svg"} {...props} />
);

 
export const ContactIcon = (props: IIconProps) => <CustomIcon path={"/svg/navigation_contact.svg"} {...props} />;
export const ContainerOverviewIcon = (props: IIconProps) => (
  <CustomIcon path={"/svg/navigation_container_overview.svg"} {...props} />
);
export const LocationOverviewIcon = (props: IIconProps) => (
  <CustomIcon path={"/svg/navigation_location_overview.svg"} {...props} />
);
export const OrderOverviewIcon = (props: IIconProps) => (
  <CustomIcon path={"/svg/navigation_order_overview.svg"} {...props} />
);
export const UserManagementIcon = (props: IIconProps) => (
  <CustomIcon path={"/svg/navigation_user_management.svg"} {...props} />
);

export const CommoditiesIcon = (props: IIconProps) => (
  <CustomIcon path={"/svg/navigation_commodities.svg"} {...props}/>
);
export const AddContainerBlueIcon = (props: IIconProps) => (
  <CustomIcon path={"/svg/navigation_add_containerBlue.svg"} {...props} />
);
export const CommoditiesBlueIcon = (props: IIconProps) => (
  <CustomIcon path={"/svg/navigation_commodities_Blue.svg"} {...props}/>
);


export const Logo = (props: IIconProps) => <CustomIcon path={"/svg/logo.svg"} {...props} />;
export const LogoWhite = (props: IIconProps) => <CustomIcon path={"/svg/logo_white.svg"} {...props} />;

export const HelpIcon = (props: IIconProps) => <CustomIcon path={"/svg/help-icon.svg"} {...props} />;
export const LogoutIcon = (props: IIconProps) => <CustomIcon path={"/svg/logout-icon.svg"} {...props} />;
