export enum entityObjectState {
  Unchanged = 0,
  Added = 1,
  Modified = 2,
  Deleted = 3
};

export type ITableEntity = {

  partitionKey: string | null;
  rowKey: string | null;
  created: Date | undefined;
  timestamp: Date | undefined;
  creator: string | null;
  modifier: string | null;
  objectState: entityObjectState;
  isActive: boolean;
}


export class Entity implements ITableEntity {
  partitionKey: string | null;
  rowKey: string | null;
  created: Date | undefined;
  timestamp: Date | undefined;
  creator: string | null;
  modifier: string | null;
  objectState: entityObjectState;
  isActive = true;
  constructor() {
    this.partitionKey = "";
    this.rowKey = "";
    this.created = new Date();
    this.timestamp = new Date();
    this.creator = "";
    this.modifier = "";
    this.objectState = entityObjectState.Added;
  }

  public isUnchanged(): boolean {
    return this.objectState === entityObjectState.Unchanged;
  };

  public setDeleted() {
    this.objectState = entityObjectState.Deleted;
  };

  public isDeleted(): boolean {
    return this.objectState === entityObjectState.Deleted;
  };

  public setModified() {
    if (!this.isDeleted() && !this.isAdded()) // ObjectState 'Deleted' or 'Added' is always stronger than 'Modified'
      this.objectState = entityObjectState.Modified;
  };

  public isModified(): boolean {
    return this.objectState === entityObjectState.Modified;
  };


  public isAdded(): boolean {
    return this.objectState === entityObjectState.Added;
  };

  public isAddedOrModified(): boolean {
    return this.objectState === entityObjectState.Added || this.objectState === entityObjectState.Modified;
  };

  public setOriginalObjectState() {
    this.objectState = this.objectState;
  };


}
