import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";

import { Theme, createStyles, lighten } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import TableContainer from "@mui/material/TableContainer";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Grid,
  Button,
  Alert,
} from "@mui/material";

import { makeStyles } from "tss-react/mui";
import {
  LocationManagementContext,
  LocationManagementContextProvider,
} from "./context/location-management-context";
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiSwitch-root": {
      margin: theme.spacing(1),
    },
    "& .MuiTypography-root": {
      margin: theme.spacing(1),
    },
  },
  container: {},
  card: {
    padding: "10px",
  },
  table: {
    padding: theme.spacing(2),
    width: "100%",
  },
  diagram: {
    marginTop: 50,
    padding: 30,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

interface IEventsPageProps {}
const LocationsPageContent = () => {
  const { loading, fetchEquipments, listEquipments } = useContext(
    LocationManagementContext
  );
  const [treeDisplay, setTreeDisplay] = React.useState<[]>([]);

  useEffect(() => {
    if (listEquipments.length === 0) {
      console.log("fetching Tree");
      fetchEquipments();
    } else {
      setTreeDisplay(listEquipments);
    }
  }, [listEquipments]);

  return (
    <Paper style={{ width: "100%" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          {/* <TableHead>
                        <TableRow>
                            {this.props.isLoading && <LinearProgress style={{ marginTop: "10px" }} />}
                            {this.props.isRecieving && <LinearProgress style={{ marginTop: "10px", backgroundColor: "green" }} />}
                            {this.props.error && <Alert severity="error">{this.props.error}</Alert>}
                        </TableRow>
                        <TableRow>
                            <TableCell>Name  </TableCell>
                            <TableCell>Last Info  </TableCell>
                            <TableCell>LastUpdate  </TableCell>
                            <TableCell>Hosting  </TableCell>
                            <TableCell>IsActive  </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.applications ? (this.props.applications.map((application: MonitorStatusStore.Application) =>
                            <TableRow key={application.rowKey} style={{ backgroundColor: this.getRowColor(application.status) }}>
                                <TableCell >{application.name}</TableCell >
                                <TableCell >{application.lastInfo}</TableCell >
                                <TableCell >{new Date(application.lastUpdate).toLocaleString()}</TableCell >
                                <TableCell ><Link target="_blank" rel="noreferrer" href={application.hostingInfo}>link</Link></TableCell >
                                <TableCell >{application.isActive.toString()} {
                                    application.status === 2 && application.isReported === false ? <Button variant="contained" color="primary" onClick={() => this.props.setAlarmOff(application.alertRowKey, application.rowKey)} endIcon={<ReplayIcon />}>silent email alarm</Button> : null
                                }</TableCell >
                            </TableRow >
                        )) : null

                        }
                    </TableBody> */}
        </Table>
      </TableContainer>
    </Paper>
  );
};

export const MonitorPage: FunctionComponent<IEventsPageProps> = (props) => {
  const { classes } = useStyles();
  return (
    <LocationManagementContextProvider>
      <Grid container className={classes.container}>
        <LocationsPageContent />
      </Grid>
    </LocationManagementContextProvider>
  );
};
