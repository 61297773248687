import React, { FunctionComponent } from "react";
import { Grid } from "@mui/material";
import { ClipLoader } from "react-spinners";
import { PRIMARY_COLOR } from "../../../styles/theme";
import Backdrop from "@mui/material/Backdrop";
import { createStyles, Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";
interface ILoadingIndicatorProps {
    className?: string;
    size?: number;
}
const useStyles = makeStyles()((theme: Theme) => ({
    backdrop: {
        zIndex: 1500,
        color: "#fff",
    },
}));

export const TransparentLoadingIndicator: FunctionComponent<ILoadingIndicatorProps> = (props) => {
    const { classes } = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <Grid container={true} justifyContent={"center"} alignItems={"center"} {...props}>
            <Grid item={true}>
                <ClipLoader size={props.size ? props.size : 50} color={PRIMARY_COLOR} />
            </Grid>
        </Grid>
    );
};

export const LoadingIndicator: FunctionComponent<ILoadingIndicatorProps> = (props) => {
    const { classes } = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <Grid container={true} justifyContent={"center"} alignItems={"center"} {...props}>
            <Grid item={true}>
                <Backdrop className={classes.backdrop} open={true}>
                    <ClipLoader size={props.size ? props.size : 50} color={PRIMARY_COLOR} />
                </Backdrop>
            </Grid>
        </Grid>
    );
};
