import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Drawer,
  Grid,
  Icon,
  List,
  ListItemIcon,
  MenuItem,
  Theme,
} from "@mui/material";
import { DRAWER_MOBILE_WIDTH } from "../../styles/theme";
import { useNavigate, useLocation } from "react-router-dom";
// import { SnackbarSeverity, useSnackbar } from "../../context/SnackbarContext";
import { IMenuItem, menuItems } from "./menu-items";
import { LoackerService } from "../../services/service";
import { UserRole } from "../../models/types/graphql-global-types";
import { SidebarContext } from "./context/sidebar-context";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0 4px 16px 0 rgba(100, 101, 103, 0.5)",
    width: DRAWER_MOBILE_WIDTH,
  },
  logoContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  list: {
    padding: 0,
  },
  listItemRoot: {
    width: "100%",
  },
  menuItemRoot: {
    padding: 0,
    minHeight: 50,
  },
  menuItemSelected: {
    backgroundColor: "rgba(255, 255, 255, 0.25) !important",
  },
  iconContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: "100%",
  },
}));

interface ISidebarMobileProps {}

export const SidebarMobile: FunctionComponent<ISidebarMobileProps> = (
  props
) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [roles, setRoles] = useState<UserRole[]>([]);
  const { sidebarOpen, toggleSidebar } = useContext(SidebarContext);

  useEffect(() => {
    if (LoackerService.isLoggedIn() === false) {
      return;
    }

    if (roles == undefined || roles.length <= 0) {
      // console.log('getting roles');
      LoackerService.fetchUserProfile().then((response) => {
        const userRoles = LoackerService.getUserRoles();
        if (userRoles) {
          setRoles(userRoles);
        }
      });
    } else {
    }
  }, [roles]);

  const onMenuItemClick = (item: IMenuItem) => {
    if (item.route) {
      navigate(item.route);
    } else if (!item.route) {
      // showSnackbar("Coming soon", SnackbarSeverity.INFO);
    }
  };

  const selectedMenuItem = menuItems.find((item) =>
    location.pathname.includes(item.route || "---")
  );

  const isItemSelected = (item: IMenuItem): boolean => {
    return selectedMenuItem ? selectedMenuItem.id === item.id : false;
  };

  // const userRole = LoackerService.getUserRoles();
  // const roles = userRole ? userRole : [];
  return (
    <Fragment>
      <Drawer
        anchor={"left"}
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
        open={sidebarOpen}
        onClose={toggleSidebar}
      >
        <Grid>
          <Grid item xs className={classes.logoContainer}>
            <img
              src={"/images/logo_white.svg"}
              className={classes.logo}
              alt=""
            />
          </Grid>
          <Grid item>
            <List className={classes.list}>
              {menuItems
                .filter((m) =>
                  m.roles
                    ? m.roles.some((allowedRole) => roles.includes(allowedRole))
                    : true
                )
                .map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => onMenuItemClick(item)}
                    selected={isItemSelected(item)}
                  >
                    <ListItemIcon>
                      <Icon className={classes.iconContainer}>{item.icon}</Icon>
                    </ListItemIcon>
                  </MenuItem>
                ))}
            </List>
          </Grid>
        </Grid>
      </Drawer>
    </Fragment>
  );
};
