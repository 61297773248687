import { UserRole } from "./types/graphql-global-types";

export interface IUserRoleDictionaryEntry {
  accessTo: UserRole[];
  hasAccessToMultipleLoackerCompanies: boolean;
  canEditUserWithSameRole: boolean;
  canDeleteUsers: boolean;
  canHaveSeparateLocations: boolean;
  canHaveSeparateBusinessClients: boolean;
  canDownloadEveryWeightOverviewPass: boolean;
  mustHaveAssignedLocations: boolean;
  canSeeContainerIds: boolean;
  canDownloadEmptyingForm: boolean;
}

export interface IUserRoleDictionary {
  [key: string]: IUserRoleDictionaryEntry;
}

export const UserRoleDictionary: IUserRoleDictionary = {
  [UserRole.SuperValue]: {
    accessTo: Object.values(UserRole),
    hasAccessToMultipleLoackerCompanies: true,
    canEditUserWithSameRole: true,
    canDeleteUsers: true,
    canHaveSeparateLocations: false,
    canDownloadEveryWeightOverviewPass: true,
    canHaveSeparateBusinessClients: false,
    mustHaveAssignedLocations: false,
    canSeeContainerIds: false,
    canDownloadEmptyingForm: true,
  },
  [UserRole.ADMIN]: {
    accessTo: Object.values(UserRole),
    hasAccessToMultipleLoackerCompanies: true,
    canEditUserWithSameRole: true,
    canDeleteUsers: true,
    canHaveSeparateLocations: false,
    canDownloadEveryWeightOverviewPass: true,
    canHaveSeparateBusinessClients: false,
    mustHaveAssignedLocations: false,
    canSeeContainerIds: false,
    canDownloadEmptyingForm: true,
  },
  [UserRole.CUSTOMER_SERVICE]: {
    accessTo: [UserRole.BUSINESS_CLIENT, UserRole.SUPER_BUSINESS_CLIENT, UserRole.CUSTOMER_SERVICE],
    hasAccessToMultipleLoackerCompanies: false,
    canEditUserWithSameRole: false,
    canDeleteUsers: false,
    canHaveSeparateLocations: false,
    canDownloadEveryWeightOverviewPass: false,
    canHaveSeparateBusinessClients: false,
    mustHaveAssignedLocations: false,
    canSeeContainerIds: false,
    canDownloadEmptyingForm: true,
  },
  [UserRole.SUPER_BUSINESS_CLIENT]: {
    accessTo: [UserRole.BUSINESS_CLIENT, UserRole.SUPER_BUSINESS_CLIENT],
    hasAccessToMultipleLoackerCompanies: false,
    canEditUserWithSameRole: false,
    canDeleteUsers: false,
    canHaveSeparateLocations: false,
    canDownloadEveryWeightOverviewPass: false,
    canHaveSeparateBusinessClients: true,
    mustHaveAssignedLocations: false,
    canSeeContainerIds: false,
    canDownloadEmptyingForm: false,
  },
  [UserRole.BUSINESS_CLIENT]: {
    accessTo: [],
    hasAccessToMultipleLoackerCompanies: false,
    canEditUserWithSameRole: false,
    canDeleteUsers: false,
    canHaveSeparateLocations: true,
    canDownloadEveryWeightOverviewPass: false,
    canHaveSeparateBusinessClients: true,
    mustHaveAssignedLocations: false,
    canSeeContainerIds: false,
    canDownloadEmptyingForm: false,
  },
};
