import * as React from "react";
import { Navigate, Route, RouteProps } from "react-router";
import { Outlet, useLocation } from "react-router-dom";
import { LoackerService } from "../services/service";

interface ISidebarContextProviderProps {}

export const PrivateRoute: React.FunctionComponent<
  ISidebarContextProviderProps
> = ({ ...props }) => {
  const isLoggedIn = LoackerService.isLoggedIn();

  console.log(` APP ${isLoggedIn}`);
  console.log(` PrivateRoute ${isLoggedIn}`);

  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={`/login`} replace state={{ location }} />
  );
};
