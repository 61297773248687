import { createTheme } from "@mui/material/styles";

export const PRIMARY_COLOR = "#005f98";
export const INFO_COLOR = "#92b976";
export const ERROR_COLOR = "#F8AE51";
export const SUCCESS_COLOR = "#43a047";
export const WARNING_COLOR = "#e5ac58";
export const DRAWER_WIDTH = 220;
export const DRAWER_MOBILE_WIDTH = 70;
export const PAPER = "#dbdbdb";

export const AppTheme = createTheme({
  palette: {
    primary: {
      light: "#337fac",
      main: PRIMARY_COLOR,
      dark: "#00426a",
      contrastText: "#fff",
    },
    secondary: {
      light: "#F2F2F2",
      main: "#B0B3B4",
      dark: "#7b7d7d",
      contrastText: "#000",
    },
    error: {
      main: ERROR_COLOR,
      light: ERROR_COLOR,
      dark: ERROR_COLOR,
    },
  },
  // @ts-ignore
  customColors: {
    background: "#16181f",
  },
  typography: {
    fontFamily: "'Open Sans', 'Helvetica Neue',Helvetica,Arial,sans-serif",
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: "1px solid " + PRIMARY_COLOR,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        height: "40px",
        "& $notchedOutline": {
          borderColor: "#2b303e",
        },
      },
      notchedOutline: {
        borderRadius: "0",
        borderWidth: "2px",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 12px) scale(1)",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "0",
        boxShadow: "none",
      },
      contained: {
        boxShadow: "none",
        minWidth: 105,
      },
    },
  },
});
