/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ContainerAction {
  AUSTAUSCHEN = "AUSTAUSCHEN",
  HOLEN = "HOLEN",
  LEEREN = "LEEREN",
  REINIGEN = "REINIGEN",
  SOFORTBELADUNG = "SOFORTBELADUNG",
}

export enum IntervalType {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  WEEKLY = "WEEKLY",
  YEARLY = "YEARLY",
}

export enum OrderDocumentType {
  PDF = "PDF",
  PHOTO = "PHOTO",
}

export enum Timeframe {
  ALL_DAY = "ALL_DAY",
  MORNING = "MORNING",
  NOON = "NOON",
}

export enum UserRole {
  READ_ONLY = "READ_ONLY",
  ADMIN = "ADMIN",
  TESTER = "TESTER",
  GUEST = "GUEST",
  SuperValue = "SuperValue",
  NEWS_ADMIN = "NEWS_ADMIN",
  TOURS_ADMIN = "TOURS_ADMIN",
  PRICEZONES_ADMIN = "PRICEZONES_ADMIN",
  DIGITAL_ASSET_ADMIN = "DIGITAL_ASSET_ADMIN",
  CONTAINERS_ADMIN = "CONTAINERS_ADMIN",
  COMMODITIES_ADMIN = "COMMODITIES_ADMIN",
  BUSINESS_CLIENT = "BUSINESS_CLIENT",
  CUSTOMER_SERVICE = "CUSTOMER_SERVICE",
  SUPER_BUSINESS_CLIENT = "SUPER_BUSINESS_CLIENT",
  UNDEFINED = "UNDEFINED",
  USERREQUEST_ADMIN = "USERREQUEST_ADMIN",
  DIGIDO = "DIGIDO",

}

export enum WeekType {
  CW = "CW",
  EVEN_CW = "EVEN_CW",
  UNEVEN_CW = "UNEVEN_CW",
}

export interface AssignedAccount {
  recyNumber: number;
  recyUuid: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
