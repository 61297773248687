import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Link from "@mui/material/Link";
import { PrivateRoute } from "./components/PrivateRoute";
import { isTablet, isMobile } from "react-device-detect";
import { Portal } from "./pages/Portal";

import { CatalogPage } from "./components/CatalogPage";
import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";
import { Login } from "./pages/Login";
import { LoackerService } from "./services/service";
export const showMobileView = isMobile || isTablet;
import "./custom.css";

const useStyles = makeStyles()((theme: Theme) => ({
  logoContainer: {
    flexGrow: 1,
  },
  logo: {
    height: "30px",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  container: {
    display: "flex",
    flexFlow: "column",
    minHeight: "100vh",
    height: "100vh",
    background: theme.palette.background.default,
  },
}));

export default function App() {
  const { classes } = useStyles();
  const isLoggedIn = LoackerService.isLoggedIn();

  console.log(` APP ${isLoggedIn}`);

  return (
    <div className={classes.container}>
      <Routes>
        <Route path={"/"} element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path={"/portal/*"} element={<Portal />} />
        </Route>
      </Routes>
    </div>
  );
}
