import React, { useContext, useState } from "react";
import { Theme, createStyles, lighten } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, Paper } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  DigidoManagementContext,
  DigidoManagementContextProvider,
} from "./context/digido-management-context";
import { ExchangeViewData, ExchangeViewEntity } from "./taskrequests";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Alert,
  Progress,
} from "reactstrap";
import readXlsxFile from "read-excel-file";
import ModalContainer from "./ModalContainer";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
  },
  container: {
    width: "100%",
    position: "relative",
  },
  card: {},
  table: {
    padding: theme.spacing(2),
    width: "100%",
    marginTop: "20px",
    "& img": {
      width: "100%",
    },
  },
  diagram: {
    marginTop: 50,
    padding: 30,
  },
  img: {
    widh: "250px",
    borderRadius: "8px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

interface IEventsPageProps {}

export const DigidoContent: React.FunctionComponent<IEventsPageProps> = (
  props
) => {
  const { classes } = useStyles();
  const {
    loading,
    sendOrders,
    listEquipments,
    transactionResults,
    error,
    progress,
  } = useContext(DigidoManagementContext);

  const [displayEntity, setDisplayEntity] = useState<
    ExchangeViewData | undefined
  >(undefined);

  React.useEffect(() => {
    const initialValues = new ExchangeViewEntity();
    setDisplayEntity(initialValues);
  }, []);

  const sendMessage = (e: any) => {
    if (!displayEntity) {
      return;
    }
    e.preventDefault();

    sendOrders(displayEntity);
  };

  if (!displayEntity) {
    return null;
  }

  const uploadFile = (event: any) => {
    if (event != null && event.target.files != null) {
      console.log("uploadFile...");

      event.persist();
        readXlsxFile(event.target.files[0]).then((rows: any) => {

    
        //remove headers//
        rows.shift();
        rows.map((row: any) => {
          //reformat date
            const serial = row[1];

            if (serial instanceof Date && !isNaN(serial.valueOf())) {
                row[1] = serial.toLocaleDateString("de-AT");
            }
            else {
                var utc_days = Math.floor(serial - 25569);
                var utc_value = utc_days * 86400;
                var date_info = new Date(utc_value * 1000);
                row[1] = date_info.toLocaleDateString("de-AT");
            }
         
        });

        if (displayEntity) {
          setDisplayEntity({ ...displayEntity, ["bodyMessage"]: rows });
        }
      });
    }
  };
  const handleEditDisplayEntity = (event: any) => {
    // const tempSelectedEntity = {...displaySelectedEntity};

    const { name, value } = event.target;
    console.log(event);
    console.log(name);
    console.log(value);
    if (displayEntity && name) {
      let valueToUpdate = { ...displayEntity };

      // valueToUpdate[key] = value;

      // setDisplaySelectedEntity(valueToUpdate);
      setDisplayEntity({ ...displayEntity, [name]: value });
      console.log(valueToUpdate);
    }
  };
  return (
    <Paper className={classes.table}>
      {loading && <CircularProgress color="primary" />}
      <React.Fragment>
        <Row>
          <Col>
            <Card style={{ marginBottom: "5px" }}>
              <CardBody>
                {/* <BlockUi tag="div" blocking={loading}>*/}
                <Form onSubmit={sendMessage}>
                  <FormGroup row>
                    <Col sm={3}>
                      <Label>{"Geschäftsfalles"}</Label>
                      <Input
                        disabled={loading}
                        type="select"
                        name="moduleId"
                        id="moduleId"
                        bsSize="lg"
                        value={displayEntity.moduleId}
                        onChange={handleEditDisplayEntity}
                      >
                        <option value="Module1">Module 1 + 2</option>
                        <option value="Module3">Module 3</option>
                        <option value="Gestra">Module Gestra</option>
                        <option value="BET">Module BET</option>
                      </Input>
                    </Col>
                    <Col sm={3}>
                      <Label>Dateiimport </Label>
                      <Input
                        disabled={loading}
                        type="file"
                        name="file"
                        id="exampleFile"
                        required={true}
                        onChange={uploadFile}
                      />
                      <FormText color="muted">CSV UTF-8 Datei</FormText>
                    </Col>
                    <Col sm={4}>
                      <Label>Extra kommentare </Label>
                      <Input
                        disabled={loading}
                        type="text"
                        name="comment"
                        placeholder="Extra kommentare "
                        value={displayEntity.comment}
                        bsSize="lg"
                        onChange={handleEditDisplayEntity}
                      />
                    </Col>
                    <Col sm={2}>
                      <Button type="submit" className="btn btn-primary btn-lg">
                        Send Order{" "}
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
                {error && <Alert color="danger">{error} </Alert>}
                <Label>
                  Gesamtergebnisse :{transactionResults.length}
                </Label>{" "}
                {transactionResults.length ===
                displayEntity.bodyMessage.length - 1 ? (
                  <Label>Prozess abgeschlossen.</Label>
                ) : null}
                <Progress bar animated value={progress} />
                <Container
                  className="themed-container"
                  style={{ maxHeight: "500px", overflowY: "scroll" }}
                >
                  {transactionResults.length <= 0 ? null : (
                    <table
                      className="table table-striped"
                      aria-labelledby="tabelLabel"
                    >
                      <thead>
                        <tr>
                          <th>TransactionId</th>
                          <th>Wiegescheinnummer</th>
                          <th>Sent data</th>
                          <th>Response Code</th>
                          <th>Response Confirmation</th>
                          <th>Response Error</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactionResults.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.transactionId}</td>
                              <td>{val.weightNoteNumber}</td>
                              <td>
                                <ModalContainer data={val.sendedData} />
                              </td>
                              <td>{val.responseCode}</td>
                              <td>{val.responseConfirmation}</td>
                              <td>{val.responseError}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </Container>
                {/*</BlockUi>*/}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={{ marginBottom: "5px" }}>
              <CardBody>
                <Form noValidate>
                  <FormGroup row>
                    <Col md={6}>
                      <Label for="senderIdentifier">
                        Organization Identification
                      </Label>
                      <Input
                        type="select"
                        name="senderIdentifier"
                        id="senderIdentifier"
                        bsSize="lg"
                        value={displayEntity.senderIdentifier}
                        onChange={handleEditDisplayEntity}
                      >
                        <option value="9008391777335">
                          Loacker Goetzis 9008391777335
                        </option>
                        <option value="9008391785996">
                          Loacker Lustenau 9008391785996
                        </option>
                        <option value="9008391785828">
                          {"Loacker Recycling Götzis (Zentrale)"}
                        </option>
                      </Input>
                    </Col>
                    <Col md={6}>
                      <Label>Streckenteilnehmer/ IntermediateParty</Label>
                      <Input
                        type="select"
                        name="intermediateParty"
                        id="intermediateParty"
                        bsSize="lg"
                        value={displayEntity.intermediateParty}
                        onChange={handleEditDisplayEntity}
                      >
                        <option value="9008390475171">ARA 9008390475171</option>
                        <option value="9008390384640">AGR 9008390384640</option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md={6}>
                      <Label for="address">Url Client (version)</Label>
                      <Input
                        type="text"
                        name="address"
                        placeholder="Url Client"
                        bsSize="lg"
                        value={displayEntity.address}
                        onChange={handleEditDisplayEntity}
                      />
                    </Col>
                    <Col md={3}>
                      <Label for="userName">UserName</Label>
                      <Input
                        type="text"
                        name="userName"
                        placeholder="UserName"
                        bsSize="lg"
                        value={displayEntity.userName}
                        onChange={handleEditDisplayEntity}
                      />
                    </Col>
                    <Col md={3}>
                      <Label for="password">Password</Label>
                      <Input
                        type="text"
                        name="password"
                        placeholder="Password"
                        bsSize="lg"
                        value={displayEntity.password}
                        onChange={handleEditDisplayEntity}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md={6}>
                      <Card>
                        <CardBody>
                          <Label>HandOver Data</Label>
                          <Col>
                            <Label for="abfallart">Movement Code</Label>
                            <Input
                              type="text"
                              name="movementCode"
                              placeholder="Movement Code"
                              value={displayEntity.movementCode}
                              bsSize="lg"
                              onChange={handleEditDisplayEntity}
                            />
                          </Col>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card>
                        <CardBody>
                          <Label>TakeOver Data</Label>
                          <Col>
                            <Label for="abfallart">Movement Code</Label>
                            <Input
                              type="text"
                              name="takeOverMovementCode"
                              placeholder="Movement Code"
                              value={displayEntity.takeOverMovementCode}
                              bsSize="lg"
                              onChange={handleEditDisplayEntity}
                            />
                            <Label for="abfallart">UnloadingLocation</Label>
                            <Input
                              type="select"
                              name="unloadingLocation"
                              id="unloadingLocation"
                              bsSize="lg"
                              value={displayEntity.unloadingLocation}
                              onChange={handleEditDisplayEntity}
                            >
                              <option value="9008391777335">
                                Loacker Goetzis 9008391777335
                              </option>
                              <option value="9008391785996">
                                Loacker Lustenau 9008391785996
                              </option>{" "}
                              <option value="9008391785828">
                                {"Loacker Recycling Götzis (Zentrale)"}
                              </option>
                            </Input>
                          </Col>
                        </CardBody>
                      </Card>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col md={6}>
                      <Card>
                        <CardBody>
                          <Label>Carrier Data</Label>
                          <Col>
                            <Label>Arts des Transport</Label>
                            <Input
                              type="select"
                              name="transportArt"
                              id="transportArt"
                              bsSize="lg"
                              value={displayEntity.transportArt}
                              onChange={handleEditDisplayEntity}
                            >
                              <option value="9008390100059">
                                LKW 9008390100059
                              </option>
                              <option value="9008390100042">
                                Bahn 9008390100042
                              </option>
                              <option value="9008390101834">
                                Kombinierten Mobiler 9008390101834
                              </option>
                            </Input>
                          </Col>
                        </CardBody>
                      </Card>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </Paper>
  );
};

export const DigidoPage: React.FunctionComponent<IEventsPageProps> = (
  props
) => {
  const { classes } = useStyles();
  return (
    <DigidoManagementContextProvider>
      <Grid container className={classes.container}>
        <DigidoContent />
      </Grid>
    </DigidoManagementContextProvider>
  );
};
