import * as React from "react";
import { Theme, Typography } from "@mui/material";

import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme: Theme) => ({
  titleText: {
    fontSize: 24,
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.1,
    letterSpacing: "-0.2px",
    textTransform: "uppercase",
    marginBottom: 16,
  },
}));

interface IHeaderProps {
  title: string;
}

export const PageHeading: React.FunctionComponent<IHeaderProps> = (props) => {
  const { ...rest } = props;
  const { classes } = useStyles();
  return (
    <Typography className={classes.titleText} {...rest} component="h1">
      {props.title}
    </Typography>
  );
};
