import "bootstrap/dist/css/bootstrap.css";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import theme from "./theme";
import { SnackbarProvider } from "./context/SnackbarContext";
import { AppContextProvider } from "./context/AppContext";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);
import i18n from "./utils/i18n";
root.render(
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <AppContextProvider>
          <BrowserRouter>
            <CssBaseline />
            <App />
          </BrowserRouter>
        </AppContextProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </I18nextProvider>
);
