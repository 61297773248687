import React, { FunctionComponent } from "react";
import { Theme, CardProps, Card } from "@mui/material";
import { makeStyles } from "tss-react/mui";
export const LOGIN_CARD_WIDTH = 350;
export const LOGIN_CARD_HEIGHT = 300;

const useStyles = makeStyles()((theme: Theme) => ({
  card: {
    maxWidth: LOGIN_CARD_WIDTH,
    height: LOGIN_CARD_HEIGHT,
    width: "80%",
    padding: theme.spacing(4),
  },
}));

interface ILoginCardProps extends CardProps {}

export const LoginCard: FunctionComponent<ILoginCardProps> = (props) => {
  const { classes } = useStyles();
  const { className, children, ...rest } = props;
  const cn = className ? `${className} ${classes.card}` : classes.card;
  return (
    <Card className={cn} {...rest} elevation={2}>
      {children}
    </Card>
  );
};
