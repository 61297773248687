import React from "react";
import { IPath, PATH } from "../../router/path";
import { ReactElement } from "react";
import {
  ContactIcon,
  ContainerOverviewIcon,
  AddContainerIcon,
  LocationOverviewIcon,
  CommoditiesIcon,
} from "../../assets/custom-icons";
import {
  ViewHeadline,
  PermIdentity,
  EventAvailable,
  RateReview,
  ImportExport,
  Bookmark,
  CloudUpload,
  FaceOutlined,
  Category,
  ArtTrack,
  GroupWork,
} from "@mui/icons-material";
import SvgIcon from "@mui/material/SvgIcon";
import MapIcon from "@mui/icons-material/Map";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

import AppsIcon from "@mui/icons-material/Apps";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import SourceIcon from "@mui/icons-material/Source";
export interface IMenuItem extends IPath {
  title: string;
  icon: ReactElement;
  action?: boolean;
}

const iconStyle = {
  color: "white",
  height: 20,
  width: 20,
};

export const menuItems: IMenuItem[] = [
  {
    ...PATH.CATALOG,
    title: "navigation.home",
    icon: <AppsIcon style={iconStyle} />,
  },
  {
   ...PATH.DIGIDO,
    title: "navigation.digido",
    icon: <AppsIcon style={iconStyle} />,
  },
  {
    ...PATH.INFO,
    title: "navigation.info",
    icon: <FormatListNumberedIcon style={iconStyle} />,
  },
  {
    ...PATH.ARCHITECTURE,
    title: "navigation.architecture",
    icon: <ArchitectureIcon style={iconStyle} />,
  },
  {
    ...PATH.CENTRALGATEWAY,
    title: "navigation.central",
    icon: <SourceIcon style={iconStyle} />,
  },
  {
    ...PATH.EXTERNALGATEWAY,
    title: "navigation.external",
    icon: <SourceIcon style={iconStyle} />,
  },
  {
      ...PATH.ATGOLORSDATAAPI,
      title: "navigation.atgolorsdataapi",
    icon: <SourceIcon style={iconStyle} />,
  },
  {
    ...PATH.HANDELSAPP,
    title: "navigation.handelsapp",
    icon: <SourceIcon style={iconStyle} />,
  },
  {
    ...PATH.AZURE,
    title: "navigation.azure",
    icon: <SourceIcon style={iconStyle} />,
  },
  {
    ...PATH.AUTH,
    title: "navigation.auth",
    icon: <MapIcon style={iconStyle} />,
  },
];
