import { FunctionComponent, ComponentType } from "react";
import { AppsInfoPage } from "../components/AppsInfo";
import { Arquitecture } from "../components/Arquitecture";
import { AuthAzurePage } from "../components/AuthAzure";
import { CatalogPage } from "../components/CatalogPage";
import { CentralGatewayPage } from "../components/CentralGateway";
import { AtgolorsdataapiPage } from "../components/AtgolorsdataapiPage";
import { ExternalGatewayPage } from "../components/ExternalGateway";
import { GamesPage } from "../components/Games";
import { HandelsappPage } from "../components/Handelsapp";
import { MonitorPage } from "../components/monitor/MonitorPage";
import { WebsitesAzurePage } from "../components/WebsitesAzure";
import { UserRole } from "../models/types/graphql-global-types";
import { DigidoPage } from "../components/Digido/DigidoPage";


const PORTAL_PATH = "/portal";

export interface IPath {
  id: string;
  route?: string;
  component?: FunctionComponent<any> | ComponentType | undefined;
  roles?: UserRole[];
}

interface IPathObject {
  [key: string]: IPath;
}

export const PATH: IPathObject = {
  CATALOG: {
    id: "catalog",
    route: `${PORTAL_PATH}/catalog`,
    component: CatalogPage,
        roles: [UserRole.ADMIN]
    },
    DIGIDO: {
        id: "digido",
        route: `${PORTAL_PATH}/digido`,
        component: DigidoPage,
        roles: [UserRole.ADMIN,UserRole.DIGIDO]
    },
  MONITOR: {
    id: "monitor",
    route: `${PORTAL_PATH}/monitor`,
    component: MonitorPage,
      roles: [UserRole.ADMIN]
  },
  ARCHITECTURE: {
    id: "architecture",
    route: `${PORTAL_PATH}/architecture`,
    component: Arquitecture,
      roles: [UserRole.ADMIN]
  },
  INFO: {
    id: "info",
    route: `${PORTAL_PATH}/info`,
    component: AppsInfoPage,
      roles: [UserRole.ADMIN]
  },
  CENTRALGATEWAY: {
    id: "central",
    route: `${PORTAL_PATH}/central`,
    component: CentralGatewayPage,
      roles: [UserRole.ADMIN]
  },
  EXTERNALGATEWAY: {
    id: "external",
    route: `${PORTAL_PATH}/external`,
    component: ExternalGatewayPage,
      roles: [UserRole.ADMIN]
  },
   ATGOLORSDATAAPI: {
      id: "atgolorsdataapi",
      route: `${PORTAL_PATH}/atgolorsdataapi`,
      component: AtgolorsdataapiPage,
       roles: [UserRole.ADMIN]
  }, HANDELSAPP: {
    id: "handelsapp",
    route: `${PORTAL_PATH}/handelsapp`,
    component: HandelsappPage,
       roles: [UserRole.ADMIN]
  },
  AZURE: {
    id: "azure",
    route: `${PORTAL_PATH}/azure`,
    component: WebsitesAzurePage,
      roles: [UserRole.ADMIN]
  },
  AUTH: {
    id: "auth",
    route: `${PORTAL_PATH}/auth`,
    component: AuthAzurePage,
      roles: [UserRole.ADMIN]
  },
  GAMES: {
    id: "games",
    route: `${PORTAL_PATH}/games`,
    component: GamesPage,
      roles: [UserRole.ADMIN]
  },
};
