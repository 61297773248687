import React, { useEffect, useState } from "react";
import BinList from "./BinListRight";
import Board from "./Board";
import Scoreboard from "./Scoreboard";
import "./garbage.css";

interface GarbageItemType {
  name: string;
  number: string;
  type: string;
}

const ButtonsGamePage: React.FC = () => {
  const [backgroundColor, setBackgroundColor] = useState<string>("#fff");
  const [gameEnded, setGameEnded] = useState(false);

  const [score, setScore] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalInitial, setTotalInitial] = useState(0);
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();

  const [correct, setCorrect] = useState(0);
  const [emoji, setEmoji] = useState("🤔");
  const [garbageItems, SetRandomGarbage] = useState<
    GarbageItemType[] | undefined
  >(undefined);

  useEffect(() => {
    if (garbageItems === undefined) {
      const initGarbageItems: GarbageItemType[] = [
        { name: "Papierentsorgung", number: "3017", type: "papier" },
        { name: "Zellulose", number: "3063", type: "papier" },
        { name: "Tetra Pak", number: "3061", type: "papier" },

        {
          name: "PET-Folien natur/bunt neu",
          number: "3561",
          type: "kunststoff",
        },
        {
          name: "HDPE/PP-Eimer natur, bunt",
          number: "3573",
          type: "kunststoff",
        },
        { name: "Verpackungskunstst. gem", number: "3552", type: "kunststoff" },

        { name: "Lebens-, Genuss-, Futtermittel", number: "4124", type: "bio" },
        { name: "Kakaobohnen Großsieb", number: "4107", type: "bio" },
        { name: "Knochen-Leim", number: "4108", type: "bio" },
        {
          name: "Flüssigkeit aus Getränkevernichtung",
          number: "4102",
          type: "bio",
        },

        { name: "Flachglas gemischt", number: "5305", type: "glass" },
        { name: "Glas bunt", number: "5300", type: "glass" },
        { name: "Glas weiss", number: "5301", type: "glass" },

        {
          name: "brennbare Shredderschwerfraktion Buchs (N)",
          number: "1096",
          type: "metal",
        },
        {
          name: "Autowrack(s) nicht trockengel.",
          number: "1030",
          type: "metal",
        },
        { name: "Dosenschrott", number: "1006", type: "metal" },

        { name: "Schamotte", number: "5020", type: "bauschutt" },
        { name: "Betonabbruch", number: "5005", type: "bauschutt" },
        { name: "Sand aus Absetzbecken", number: "5023", type: "bauschutt" },

        { name: "Friedhofsabfälle (N)", number: "4030", type: "restmüll" },
        { name: "Hausmüll (N)", number: "4008", type: "restmüll" },
        {
          name: "Industrie-/Baustellen-/Gewerbemüll",
          number: "4000",
          type: "restmüll",
        },

        {
          name: "Autowrack(s) nicht trockengel",
          number: "1030",
          type: "sonst",
        },
        {
          name: "E-Schrott Zerlegung",
          number: "6080",
          type: "sonst",
        },
        {
          name: "PKW-Reifen mit Felgen",
          number: "5206",
          type: "sonst",
        },
      ];

      const shuffledItems = initGarbageItems.sort(() => Math.random() - 0.5);
      const randomItems = shuffledItems.slice(0, 5);

      setTotalInitial(randomItems.length);
      SetRandomGarbage(randomItems);
    }
  }, [garbageItems, score, gameEnded]);

  const getRandomColor = (partyMode: boolean): string => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    if (partyMode) {
      const newTimerId = setTimeout(() => {
        setBackgroundColor(getRandomColor(true));
      }, 50);
      setTimerId(newTimerId);
    }

    return color;
  };

  const handleCorrectBinClick = (id: string, assert: boolean) => {
    let filteredItems: GarbageItemType[] = [];
    if (garbageItems) {
      filteredItems = garbageItems.filter((item) => item.name !== id);
      SetRandomGarbage(filteredItems);
    }

    console.log(garbageItems?.length);

    let tempCorrect = correct;
    let tempTotal = total;
    if (assert) {
      tempCorrect = tempCorrect + 1;
      setCorrect(tempCorrect);
      setBackgroundColor(getRandomColor(false));
    } else {
      tempCorrect = tempCorrect - 1;
      setBackgroundColor("#fff");
      setCorrect(tempCorrect);
    }
    tempTotal = tempTotal + 1;
    setTotal(tempTotal);

    const percentage =
      tempTotal === 0 ? 0 : Math.floor((tempCorrect / tempTotal) * 100);
    const value = Math.ceil(percentage / 20);
    const emojis = ["🙈", "🙉", "🙊", "🚀", "🔥", "🐐"];

    let emoji = "";

    if (value < 0) {
      emoji = "🥦";
    } else {
      emoji = emojis[value];
    }

    setEmoji(emoji);
    setScore(value);

    if (value === 5 && totalInitial === tempTotal) {
      setBackgroundColor(getRandomColor(true));
    }

    if (totalInitial === tempTotal) {
      setGameEnded(true);
    }
  };

  const handleResetGame = () => {
    setEmoji("🤔");
    setScore(0);
    setTotal(0);
    setCorrect(0);
    setBackgroundColor("#fff");
    SetRandomGarbage(undefined);
    clearTimeout(timerId);
    setGameEnded(false);
  };

  return (
    <div style={{ backgroundColor: backgroundColor }}>
      <h1>Garbage Sorting Game</h1>
      <Scoreboard
        score={score}
        emoticon={emoji}
        onResetGameClick={handleResetGame}
      />
      <div>
        {gameEnded ? <div className="EndOfGame">Game Over</div> : null}

        <Board
          garbageItems={garbageItems}
          onCorrectBinClick={handleCorrectBinClick}
        />
      </div>
    </div>
  );
};

export default ButtonsGamePage;
