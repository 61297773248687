import React from "react";
import "./garbage.css";
interface BinButtonProps {
  binType: string;
  onBinClick: (binType: string) => void;
}

const BinButton: React.FC<BinButtonProps> = ({ binType, onBinClick }) => {
  const handleClick = () => {
    onBinClick(binType);
  };

  let containerStyle = {};

  switch (binType) {
    case "kunststoff":
      containerStyle = {
        background: "linear-gradient(to bottom, #f9c00c, #ffde4b)",
      };
      break;
    case "papier":
      containerStyle = {
        background: "linear-gradient(to bottom, #e53935, #ff6f60)",
      };
      break;
    case "bio":
      containerStyle = {
        background: "linear-gradient(to bottom, #8d6e63, #bcaaa4)",
      };
      break;
    case "glass":
      containerStyle = {
        background: "linear-gradient(to bottom, #43a047, #66bb6a)",
      };
      break;
    case "metal":
      containerStyle = {
        background: "linear-gradient(to bottom, #1976d2, #42a5f5)",
      };
      break;
    case "bauschutt":
      containerStyle = {
        background: "linear-gradient(to bottom, #708090, #8d6e63)",
      };
      break;
    case "sonst":
      containerStyle = {
        background: "linear-gradient(to bottom, #5e1c99, #8d6e63)",
      };
      break;
    default:
      containerStyle = {
        // border: "5px solid white",
        // borderRadius: "50%",
        // padding: "20px",
        // color: "white",
        // fontWeight: "bold",
        // fontSize: "30px",
        // fontFamily: "Arial, sans-serif",
        // cursor: "pointer",
        // boxShadow: "5px 5px #888888",
        // outline: "none",
        background: "linear-gradient(to bottom, #424242, #757575)",
      };
      break;
  }

  return (
    <button
      className="garbage-container"
      style={containerStyle}
      onClick={handleClick}
    >
      {binType}
    </button>
  );
};

export default BinButton;
