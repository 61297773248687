import * as React from "react";

import { AppBar, Grid, Theme, Toolbar, Typography, Fab } from "@mui/material";

import Menu from "@mui/icons-material/Menu";

import { LoackerService } from "../services/service";

import { PermIdentity, ExitToApp, HelpOutline } from "@mui/icons-material";
import { FunctionComponent, useContext } from "react";
import { useNavigate, redirect, Navigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { showMobileView } from "../App";
import { SidebarContext } from "../components/sidebar/context/sidebar-context";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  appBarRoot: {},
  toolBar: {
    display: "flex",
    justifyContent: "flex-end",
    background: showMobileView
      ? theme.palette.primary.main
      : theme.palette.background.default,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  titleText: {
    color: showMobileView
      ? theme.palette.common.white
      : theme.palette.text.primary,
    display: "block",
    alignItems: "center",
    justifyItems: "center",
    fontSize: 14,
    lineHeight: 1.2,
  },
  fabWithPrimary: {
    color: "primary",
  },

  whiteIcon: {
    color: "white",
  },

  seperatorText: {
    color: showMobileView
      ? theme.palette.common.white
      : theme.palette.primary.main,
    fontWeight: "bold",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  iconButton: {
    boxShadow: showMobileView ? "none" : undefined,
    padding: 0,
    marginLeft: showMobileView ? theme.spacing(1) : theme.spacing(2),
    minWidth: showMobileView ? theme.spacing(4) : "auto",
    minHeight: showMobileView ? theme.spacing(4) : "auto",
    maxWidth: showMobileView ? theme.spacing(4) : "auto",
    maxHeight: showMobileView ? theme.spacing(4) : "auto",
  },
  menuIconButton: {
    boxShadow: showMobileView ? "none" : undefined,
    padding: 0,
    marginRight: showMobileView ? theme.spacing(1) : theme.spacing(2),
    minWidth: showMobileView ? theme.spacing(4) : "auto",
    minHeight: showMobileView ? theme.spacing(4) : "auto",
    maxWidth: showMobileView ? theme.spacing(4) : "auto",
    maxHeight: showMobileView ? theme.spacing(4) : "auto",
  },
}));

interface IToolbarProps {}

export const ToolbarCustom: FunctionComponent<IToolbarProps> = (props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { setBusinessClient } = useContext(AppContext);
  const { toggleSidebar } = useContext(SidebarContext);

  console.log(` ToolbarCustom`);

  const logout = () => {
    LoackerService.logout();
    setBusinessClient(undefined);
    navigate("/");
  };
  const user = LoackerService.getUser();

  if (!LoackerService.isLoggedIn()) {
    return <Navigate to="/login" state={user} replace={true} />;
  }

  return (
    <AppBar
      position={showMobileView ? "fixed" : "static"}
      color="inherit"
      elevation={showMobileView ? 1 : 0}
      classes={{ root: classes.appBarRoot }}
    >
      <Toolbar variant="regular" className={classes.toolBar} disableGutters>
        <Grid
          container
          justifyContent={showMobileView ? "space-between" : "flex-end"}
          alignItems={"center"}
        >
          <Grid item>
            {showMobileView && (
              <Fab
                className={classes.menuIconButton}
                color="primary"
                size="small"
                onClick={toggleSidebar}
              >
                <Menu />
              </Fab>
            )}
          </Grid>
          <Grid item xs={showMobileView}>
            <Grid
              container
              alignItems={showMobileView ? "flex-end" : "center"}
              justifyContent={showMobileView ? "flex-end" : "flex-start"}
              direction={showMobileView ? "column" : "row"}
            >
              <Typography variant="h6" noWrap className={classes.titleText}>
                {user ? (user.recyNumber ? user.recyNumber : "Internal") : ""}
              </Typography>
              {!showMobileView && (
                <Typography
                  variant="h6"
                  noWrap
                  className={classes.seperatorText}
                >
                  |
                </Typography>
              )}
              <Typography variant="h6" noWrap className={classes.titleText}>
                {user ? `${user.firstName} ${user.lastName}` : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Fab
              className={classes.iconButton}
              color="primary"
              size="small"
              onClick={() => navigate("/portal/my-profile")}
            >
              <PermIdentity />
            </Fab>
            <Fab
              className={classes.iconButton}
              color="primary"
              size="small"
              onClick={() => navigate("/portal/contact")}
            >
              <HelpOutline />
            </Fab>
            <Fab
              className={classes.iconButton}
              color="primary"
              size="small"
              onClick={() => logout()}
            >
              <ExitToApp />
            </Fab>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
