/// <reference path="../router/path.ts" />
/// <reference path="../router/path.ts" />
import store from "store";
// import React, { useContext } from "react";
// import { AppContext } from "../context/AppContext";

import { User } from "../models/login";
import { UserRole } from "../models/types/graphql-global-types";
import { BusinessClient } from "../models/types/me";
import { IUserRoleDictionaryEntry, UserRoleDictionary } from "../models/user-role-dictionary";

import lodash from "lodash";

import { isIE, requiresInteraction, tokenInfo, Request } from "./authTypes";
// import { AuthResponse, Account, AuthError } from "msal";

import { msalConfig, loginRequest, tokenRequestCentralgateway, apiConfig, } from "./authConfig";
import { AccountInfo, AuthenticationResult, PublicClientApplication } from "@azure/msal-browser";


import { Login_login_user_loackerCompany } from "../models/types/Login";
import { dropItemStringValue, dropItemNumberValue } from "../models/dropbox";


const useRedirectFlow = isIE();

enum STORE_KEYS {
  CENTRALGATEWAYTOKEN = "centralgateway_token",
  EXPIRATION_DATE_TOKEN = "loacker_expirationDate_Token",
  ACCOUNT = 'account',
  USER = "loacker_user",
  HOMEACCOUNTID = "homeAccountId",
  BUSINESSCLIENT = "loacker_business_client",
  ROLE = "loacker_role",
}

const msalApp = new PublicClientApplication(msalConfig);
export class LoackerService {

  public static async onSignOut() {

    // this.setState({
    //     ...this.state,
    //     account: {} as AuthenticationResult,
    //     isAuthenticated: false
    // });
    localStorage.setItem("account", '')
    return msalApp.logoutPopup();
  }

  public static async onSignIn(redirect: boolean) {
    if (redirect) {
      console.log('loginRedirect');
      await msalApp.loginRedirect(loginRequest);
      return "OK";
    }
    console.log('onSignIn');
    console.log(loginRequest);


    try {

      const response = await msalApp.loginPopup(loginRequest);

      await this.handleSignInResponse(response);

      var profileUser = await LoackerService.fetchUserProfile();

      console.log(profileUser);

      return "OK";

    } catch (err) {

      return `${err}`;
    }
  }

  public static async handleSignInResponse(response: AuthenticationResult) {
    if (response !== null) {
      console.log('handleSignInResponse');

      const defaultCompany: Login_login_user_loackerCompany = {
        __typename: "LoackerCompany",
        companyNumber: 140,
        name: "LoackerCompany",
        email: "kundencenter@loacker.cc",
        phone: "+43 5523 502 1111",
        imprint: "https://www.loacker.cc/impressum/",
        dataPolicy: "https://www.loacker.cc/datenschutzerklaerung/",
        termsAndConditions: "https://www.loacker.cc/agb",
      };

      //const stringRoles = account.account.idToken.extension_CustomRole.split(",");
      const stringRoles = [''];

      let roles: UserRole[] = [];
      if (!response.account) { return false; }


      const userName = response.account.username.toLowerCase();

      const userNames = response.account?.name ? response.account.name.split(' ') : null;
      const user: User = {
        __typename: "User",
        firstName: userNames ? userNames[0] : "",
        lastName: userNames ? userNames[1] : "",
        email: response.account ? userName : "",
        roles: roles,
        isActive: true,
        lastLogin: "",
        telNr: "",
        position: "",
        recyNumber: null,
        acceptedAgbAt: true,
        assignedBusinessClients: null,
        loackerCompany: defaultCompany,
        assignedLocations: null,
      };

      store.set(STORE_KEYS.USER, user);
      store.set(STORE_KEYS.ACCOUNT, response.account);
      store.set(STORE_KEYS.HOMEACCOUNTID, response.account?.homeAccountId);


      return true;

    } else {
      this.getAccounts();
      return true;
    }
  }


  public static getAccounts() {
    /**
     * See here for more info on account retrieval: 
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    const currentAccounts = msalApp.getAllAccounts();

    if (currentAccounts === null) {
      console.error("No accounts detected!");
      return;
    } else if (currentAccounts.length > 1) {
      console.warn("Multiple accounts detected.");
      // Add choose account code here
      const account = msalApp.getAccountByUsername(currentAccounts[0].username);
      store.set(STORE_KEYS.ACCOUNT, account);
      store.set(STORE_KEYS.HOMEACCOUNTID, account?.homeAccountId);

    } else if (currentAccounts.length === 1) {
      console.warn("yes");
      const account = msalApp.getAccountByUsername(currentAccounts[0].username);
      store.set(STORE_KEYS.ACCOUNT, account);
      store.set(STORE_KEYS.HOMEACCOUNTID, account?.homeAccountId);
    }
  }

  public static async acquireCentralGatewayToken(): Promise<string | undefined> {
    try {

      let tokenFounded = store.get(STORE_KEYS.CENTRALGATEWAYTOKEN);
      if (!tokenFounded) {
        let response = await this.tokenSilent(tokenRequestCentralgateway);
        console.log("tokenSilent was ok ");
        if (!response) {
          response = await msalApp.acquireTokenPopup(tokenRequestCentralgateway);
        }

        this.StoreCentralGatewayToken(response);
        return this.getToken();
      }

      const currentDate = new Date();
      const expirationDate = new Date(store.get(STORE_KEYS.EXPIRATION_DATE_TOKEN) as Date);

      if (expirationDate !== null && currentDate > expirationDate) {
        // console.log('Token has expired');
        const response = await this.tokenSilent(tokenRequestCentralgateway);
        this.StoreCentralGatewayToken(response);
      }

      return this.getToken();

    } catch (error: any) {
      // console.log("AquireTokenSilent failure");
      if (requiresInteraction(error.errorCode)) {
        // return useRedirectFlow ? msalApp.acquireTokenRedirect(tokenRequestNews) : msalApp.acquireTokenPopup(tokenRequestNews);
        throw error;
      } else {
        console.error("Non-interactive error:", error.errorCode);
        throw error;
      }
    }
  }


  public static async fetchUserProfile(): Promise<boolean> {

    console.log('fetchUserProfile...');
    // const token = await LoackerService.acquireCentralGatewayToken();
    let sucess: boolean = false;
    // if (!token) {
    //   console.log('fetchUserProfile... token!');
    //   //  showSnackbar("No Token", SnackbarSeverity.ERROR);
    //   sucess = false;
    //   return sucess;
    // }

    // setLoading(true);
    let loggedUser = this.getUser();

    if (loggedUser === undefined) {
      console.log('fetchUserProfile... loggedUser');
      sucess = false;
      return sucess;
    }

      if (!loggedUser.roles.includes(UserRole.READ_ONLY)) {
          loggedUser.roles.push(UserRole.BUSINESS_CLIENT)
          loggedUser.roles.push(UserRole.READ_ONLY)
      }

    if (loggedUser.email.toLowerCase() === 'emmanuel.lohora@loacker.cc' ||
      loggedUser.email.toLowerCase() === 'dunja.ellensohn@loacker.cc' ||
      loggedUser.email.toLowerCase() === 'dominic.kainer@loacker.cc' ||
      loggedUser.email.toLowerCase() === 'anastasija.arestov@loacker.cc' ||
      loggedUser.email.toLowerCase() === 'christoph.bachmann@loacker.cc' ||
      loggedUser.email.toLowerCase() === 'wolfgang.lederhoser@loacker.cc' ||
      loggedUser.email.toLowerCase() === 'corina.gaechter@loacker.cc' ||
      loggedUser.email.toLowerCase() === 'nathalie.fischer@loacker.cc' ||
      loggedUser.email.toLowerCase() === 'sabine.kadur@loacker.cc' ||
        loggedUser.email.toLowerCase() === 'elisabeth.kaufmann@loacker.cc') {
        if (!loggedUser.roles.includes(UserRole.ADMIN)) { loggedUser.roles.push(UserRole.ADMIN) }
    }

      if (loggedUser.email.toLowerCase() === 'hildegard.adametz@loacker.cc' || loggedUser.email.toLowerCase() === 'sarah.wackernell@loacker.cc') {
          if (!loggedUser.roles.includes(UserRole.DIGIDO)) { loggedUser.roles.push(UserRole.DIGIDO) }

      }

 
    store.set(STORE_KEYS.USER, loggedUser);
    return sucess;

  }


  public static async importRecyCommodities(clientId: string | null): Promise<any[] | undefined> {

    // console.log('importRecyCommodities...');
    const token = await this.acquireCentralGatewayToken();

    if (!token) {
      //  showSnackbar("No Token", SnackbarSeverity.ERROR);
      return undefined;
    }

    // setLoading(true);
    let loggedUser = this.getUser();

    if (loggedUser === undefined) {
      return;
    }

    const url = new URL(`${apiConfig.centralGatewayAPIUri}ImportMasterData/UpdateMasterData/?argCatalogName=Commodities&argCompanyNumber=140${clientId === null ? '' : `&argClientId=${clientId}`}`);
    const authString = `Bearer ${token}`;

    const response = fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).then(async (response) => {
      if (response && response.status !== 404) {
        const data = await response.json();



        return data;
      }

      return undefined;

    }).catch((err) => {
      throw err;
    });

    return response;

  }

  public static StoreCentralGatewayToken(response: AuthenticationResult | null) {
    if (response !== null) {
      console.log('StoreCentralGatewayToken');

      store.set(STORE_KEYS.CENTRALGATEWAYTOKEN, response.accessToken);
      store.set(STORE_KEYS.EXPIRATION_DATE_TOKEN, response.expiresOn);

    } else {
      this.getAccounts();
    }
  }

  public static async tokenSilent(argRequest: Request) {
    console.log('tokenSilent');
    argRequest.account = this.getAzureAccount();
    let response = await msalApp.acquireTokenSilent(argRequest);
    if (!response) {
      response = await msalApp.acquireTokenPopup(argRequest);
    }

    return response;
  }


  public static getAzureAccount(): AccountInfo {
    return store.get(STORE_KEYS.ACCOUNT, undefined);
  }


  public static getUserRoleDictionaryEntry(): IUserRoleDictionaryEntry {
    const roles = LoackerService.getUserRoles();
    const role = roles ? roles[0] : undefined;
    const entry = UserRoleDictionary[role || ""];
    return entry ? entry : UserRoleDictionary[UserRole.BUSINESS_CLIENT];
  }

  public static getUser(): User | undefined {
    return store.get(STORE_KEYS.USER);
  }

  public static logout() {
    msalApp.logout();
    store.clearAll();
  }

  public static getToken(): string | undefined {
    return store.get(STORE_KEYS.CENTRALGATEWAYTOKEN, undefined);
  }

  public static isLoggedIn(): boolean {
    return store.get(STORE_KEYS.USER, undefined) !== undefined;
  }

  public static getUserRoles(): UserRole[] | undefined {
    const user = store.get(STORE_KEYS.USER);
    return user ? user.roles : undefined;
  }

  public static isAdmin(): boolean {
    const roles = LoackerService.getUserRoles();

    if (roles) {
      return roles.indexOf(UserRole.ADMIN) >= 1 ? true : false;
    }
    else {
      return false;
    }
  }
  public static isTester(): boolean {
    const roles = LoackerService.getUserRoles();

    if (roles) {
      return roles.indexOf(UserRole.TESTER) >= 1 ? true : false || roles.indexOf(UserRole.ADMIN) >= 1 ? true : false;
    }
    else {
      return false;
    }
  }
  public static hasToManuallyEnterAccountNumber(): boolean {
    const roles = LoackerService.getUserRoles();
    const role = roles ? roles[0] : undefined;

    return role === UserRole.ADMIN || role === UserRole.SuperValue;
  }

  public static getBusinessClient(): BusinessClient | undefined {
    return store.get(STORE_KEYS.BUSINESSCLIENT, undefined);
  }

  public static setBusinessClient(businessClient: BusinessClient) {
    store.set(STORE_KEYS.BUSINESSCLIENT, businessClient);
  }

  public static removeBusinessClient() {
    store.remove(STORE_KEYS.BUSINESSCLIENT);
  }

  public static getErpCompanyNumber() {
    const token = store.get(STORE_KEYS.CENTRALGATEWAYTOKEN);
    // const payload = jwt.decode(token);
    return lodash.get(token, "user.erpCompanyNumber", "");
  }

  public static getCompanyNameOfBusinessClient(): string {
    const bc = store.get(STORE_KEYS.BUSINESSCLIENT) as BusinessClient;
    return bc.companyNumber === 140 ? "Loacker Götzis" : "Loacker Swiss";
  }

  public static getCompanyNumberOfBusinessClient(): number {
    const bc = store.get(STORE_KEYS.BUSINESSCLIENT) as BusinessClient;
    return bc.companyNumber;
  }

  public static canDownloadWeightOverviewPass(): boolean {
    return (
      LoackerService.getUserRoleDictionaryEntry().canDownloadEveryWeightOverviewPass ||
      LoackerService.getCompanyNumberOfBusinessClient() === 140
    );
  }

  public static canDownloadEmptyingForm(): boolean {
    return LoackerService.getUserRoleDictionaryEntry().canDownloadEmptyingForm;
  }
}


export class DropdowndataService {


  public static createDropboxItemTypesNumbers = (entity: any): dropItemNumberValue => {
    const drop =
      {
        value: entity.Id ? Number(entity.Id) : 0,
        label: entity.Label ? entity.Label : "",
      } as dropItemNumberValue;

    return drop;
  }

  public static createDropboxItemTypesStrings = (entity: any): dropItemStringValue => {
    const drop =
      {
        value: entity.Label ? entity.Label : "",
        label: entity.Id ? entity.Id : 0,
      } as dropItemStringValue;

    return drop;
  }


  public static createDropboxItem = (entity: any): dropItemStringValue => {
    const drop =
      {
        value: entity.RowKey ? entity.RowKey : "",
        label: entity.Label ? entity.Label : "",
      } as dropItemStringValue;

    return drop;
  }

  public static createSelectItem = (entity: any): dropItemStringValue => {
    const drop =
      {
        value: entity.PartitionKey ? entity.PartitionKey : "",
        label: entity.Name ? entity.Name : "",
        checked: entity.IsSelected ? entity.IsSelected : false,
      } as dropItemStringValue;

    return drop;
  }

  public static createSelectCityItem = (entity: any): dropItemStringValue => {
    const drop =
      {
        value: entity.RowKey ? entity.RowKey : "",
        label: entity.Place ? entity.Place : "",
        checked: entity.IsSelected ? entity.IsSelected : false,
      } as dropItemStringValue;

    return drop;
  }

  public static findCommoditiesItem = (entity: any): dropItemStringValue => {
    const labelFounded = DropdowndataService.getCommoditiesOptions()?.find(x => x.value === entity.PartitionKey);
    const drop =
      {
        value: labelFounded ? labelFounded.value : entity.PartitionKey,
        label: labelFounded ? labelFounded.label : entity.PartitionKey,
        checked: entity.IsSelected ? entity.IsSelected : false,
      } as dropItemStringValue;

    return drop;
  }

  public static findSiteItem = (argValue: any): dropItemStringValue => {
    const labelFounded = DropdowndataService.getSitesOptions()?.find(x => x.value === argValue);
    const drop =
      {
        value: labelFounded ? labelFounded.value : argValue,
        label: labelFounded ? labelFounded.label : argValue,
        checked: labelFounded ? true : false,
      } as dropItemStringValue;

    return drop;
  }

  public static findCityItem = (filterValue: string): dropItemStringValue | undefined => {
    const labelFounded = DropdowndataService.getCitiesOptions()?.find(x => x.value === filterValue);

    if (labelFounded === undefined) {
      return labelFounded;
    }

    const drop =
      {
        value: labelFounded.value,
        label: labelFounded.label,
        checked: true,
      } as dropItemStringValue;

    return drop;
  }


  public static async fetchSuperOrdinates(): Promise<any[] | undefined> {

    // console.log('fetchSuperOrdinates...');
    const token = await LoackerService.acquireCentralGatewayToken();

    if (!token) {
      //  showSnackbar("No Token", SnackbarSeverity.ERROR);
      return undefined;
    }

    // setLoading(true);
    const url = new URL(`${apiConfig.centralGatewayAPIUri}Superordinate?countryCode=AT`);
    const authString = `Bearer ${token}`;

    const response = fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).then(async (response) => {
      if (response && response.status !== 404) {
        const data = await response.json();


        const dropSuperordinates = data.map((entity: any) => {
          return this.createDropboxItem(entity)
        });

        store.set("dropSuperordinates", dropSuperordinates);

        return data;
      }

      return undefined;

    }).catch((err) => {
      // console.log(err)
      return undefined;
    });

    return response;

  }

  public static async fetchCommoditiesBySuperordinates(argSuperordinateKey: string): Promise<any[] | undefined> {

    // console.log('fetchCommoditiesBySuperordinates...');
    const token = await LoackerService.acquireCentralGatewayToken();

    if (!token) {
      //  showSnackbar("No Token", SnackbarSeverity.ERROR);
      return undefined;
    }

    // setLoading(true);
    const url = new URL(`${apiConfig.centralGatewayAPIUri}Commodity?type=SuperOrdinate&nextPartitionKey=${argSuperordinateKey}`);
    const authString = `Bearer ${token}`;


    const response = fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).then(async (response) => {
      if (response && response.status !== 404) {
        const result = await response.json();
        const data = JSON.parse(result.Data);

        const commoditiesSuperOrdinate = data.map((entity: any) => {
          return this.createSelectItem(entity)
        });


        store.set("commoditiesSuperOrdinate", commoditiesSuperOrdinate);

        return data;
      }

      return undefined;

    }).catch((err) => {
      // console.log(err)
      return undefined;
    });

    return response;

  }

  public static async fetchContainerTypes(): Promise<dropItemStringValue[] | undefined> {

    // console.log('fetchContainerTypes...');
    const token = await LoackerService.acquireCentralGatewayToken();

    if (!token) {
      return undefined;
    }

    const url = new URL(`${apiConfig.centralGatewayAPIUri}ContainerType?type=Select`);
    const authString = `Bearer ${token}`;

    const response = await fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).catch((err) => {
      // console.log(err)
      return undefined;
    });


    if (response && response.status !== 404) {
      const result = await response.json();
      const data = JSON.parse(result.Data);

      const dropdata = data.map((entity: any) => {
        return this.createSelectItem(entity)
      });

      if (dropdata.length >= 1) {
        store.set("dropContainerTypes", dropdata);
      }

      return dropdata;
    } else {
      return undefined;
    }

  }

  public static async fetchCommodities(): Promise<dropItemStringValue[] | undefined> {

    // console.log('fetchCommodities...');
    const token = await LoackerService.acquireCentralGatewayToken();

    if (!token) {
      return undefined;
    }

    const url = new URL(`${apiConfig.centralGatewayAPIUri}Commodity?type=Select`);
    const authString = `Bearer ${token}`;

    const response = await fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).catch((err) => {
      // console.log(err)
      return undefined;
    });


    if (response && response.status !== 404) {
      const result = await response.json();
      const data = JSON.parse(result.Data);

      const dropdata = data.map((entity: any) => {
        return this.createSelectItem(entity)
      });

      if (dropdata.length >= 1) {
        store.set("dropCommodities", dropdata);
      }

      return dropdata;
    } else {
      return undefined;
    }

  }


  public static async fetchCities(): Promise<any[] | undefined> {

    // console.log('fetchContainerSystems...');
    const token = await LoackerService.acquireCentralGatewayToken();

    if (!token) {
      //  showSnackbar("No Token", SnackbarSeverity.ERROR);
      return undefined;
    }

    // setLoading(true);
    const url = new URL(`${apiConfig.centralGatewayAPIUri}City?`);
    const authString = `Bearer ${token}`;

    const response = fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).then(async (response) => {
      if (response && response.status !== 404) {
        const data = await response.json();

        const dropdata = data.map((entity: any) => {
          return this.createSelectCityItem(entity)
        });

        console.log();

        if (dropdata.length >= 1) {
          store.set("dropCities", dropdata);
        }

        return dropdata;
      }

      return undefined;

    }).catch((err) => {
      // console.log(err)
      return undefined;
    });

    return response;

  }

  public static async fetchContainerSystems(): Promise<any[] | undefined> {

    // console.log('fetchContainerSystems...');
    const token = await LoackerService.acquireCentralGatewayToken();

    if (!token) {
      //  showSnackbar("No Token", SnackbarSeverity.ERROR);
      return undefined;
    }

    // setLoading(true);
    const url = new URL(`${apiConfig.centralGatewayAPIUri}ContainerSystem?countryCode=AT`);
    const authString = `Bearer ${token}`;

    const response = fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).then(async (response) => {
      if (response && response.status !== 404) {
        const data = await response.json();

        const dropContainerSystem = data.map((entity: any) => {
          return this.createDropboxItem(entity)
        });

        store.set("dropContainerSystem", dropContainerSystem);

        return data;
      }

      return undefined;

    }).catch((err) => {
      // console.log(err)
      return undefined;
    });

    return response;

  }

  public static async fetchPriceZones(): Promise<any[] | undefined> {

    // console.log('fetchPriceZones...');
    const token = await LoackerService.acquireCentralGatewayToken();

    if (!token) {
      //  showSnackbar("No Token", SnackbarSeverity.ERROR);
      return undefined;
    }

    // setLoading(true);
    const url = new URL(`${apiConfig.centralGatewayAPIUri}PriceZone?countryCode=AT`);
    const authString = `Bearer ${token}`;

    const response = fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).then(async (response) => {
      if (response && response.status !== 404) {
        const data = await response.json();

        const dropPriceZone = data.map((entity: any) => {
          return this.createDropboxItem(entity)
        });

        store.set("dropPriceZone", dropPriceZone);

        return data;
      }

      return undefined;

    }).catch((err) => {
      // console.log(err)
      return undefined;
    });

    return response;

  }

  public static async fetchSites(): Promise<any[] | undefined> {

    // console.log('fetchSites...');
    const token = await LoackerService.acquireCentralGatewayToken();

    if (!token) {
      //  showSnackbar("No Token", SnackbarSeverity.ERROR);
      return undefined;
    }

    // setLoading(true);
    const url = new URL(`${apiConfig.centralGatewayAPIUri}Site`);
    const authString = `Bearer ${token}`;

    const response = fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).then(async (response) => {
      if (response && response.status !== 404) {
        const data = await response.json();

        const dropSite = data.map((entity: any) => {
          return this.createDropboxItem(entity)
        });

        store.set("dropSite", dropSite);

        return data;
      }

      return undefined;

    }).catch((err) => {
      // console.log(err)
      return undefined;
    });

    return response;

  }

  public static async fetchVehicleGroups(): Promise<any[] | undefined> {

    // console.log('fetchVehicleGroups...');
    const token = await LoackerService.acquireCentralGatewayToken();

    if (!token) {
      //  showSnackbar("No Token", SnackbarSeverity.ERROR);
      return undefined;
    }

    // setLoading(true);
    const url = new URL(`${apiConfig.centralGatewayAPIUri}VehicleGroup?countryCode=AT`);
    const authString = `Bearer ${token}`;

    const response = fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).then(async (response) => {
      if (response && response.status !== 404) {
        const data = await response.json();


        const dropContainerSystem = data.map((entity: any) => {
          return this.createDropboxItem(entity)
        });

        store.set("dropVehicleGroups", dropContainerSystem);

        return data;
      }

      return undefined;

    }).catch((err) => {
      // console.log(err)
      return undefined;
    });

    return response;

  }

  public static async fetchTourServices(): Promise<any[] | undefined> {

    // console.log('fetchTourServices...');
    const token = await LoackerService.acquireCentralGatewayToken();

    if (!token) {
      //  showSnackbar("No Token", SnackbarSeverity.ERROR);
      return undefined;
    }

    // setLoading(true);
    const url = new URL(`${apiConfig.centralGatewayAPIUri}TourService?countryCode=AT`);
    const authString = `Bearer ${token}`;

    const response = fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).then(async (response) => {
      if (response && response.status !== 404) {
        const data = await response.json();


        const dropContainerSystem = data.map((entity: any) => {
          return this.createDropboxItem(entity)
        });

        store.set("dropTourServices", dropContainerSystem);

        return data;
      }

      return undefined;

    }).catch((err) => {
      // console.log(err)
      return undefined;
    });

    return response;

  }

  public static async fetchTourContainerTypes(): Promise<any[] | undefined> {

    // console.log('fetchTourContainerTypes...');
    const token = await LoackerService.acquireCentralGatewayToken();

    if (!token) {
      //  showSnackbar("No Token", SnackbarSeverity.ERROR);
      return undefined;
    }

    // setLoading(true);
    const url = new URL(`${apiConfig.centralGatewayAPIUri}Type?typeName=tourContainerType`);
    const authString = `Bearer ${token}`;

    const response = fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).then(async (response) => {
      if (response && response.status !== 404) {
        const data = await response.json();


        const dropResult = data.map((entity: any) => {
          return this.createDropboxItemTypesNumbers(entity)
        });

        // console.log(dropResult);

        store.set("dropTourContainerTypes", dropResult);

        return data;
      }

      return undefined;

    }).catch((err) => {
      // console.log(err)
      return undefined;
    });

    return response;

  }

  public static async fetchTourIntervalTypes(): Promise<any[] | undefined> {

    // console.log('fetchTourIntervalTypeTypes ...');
    const token = await LoackerService.acquireCentralGatewayToken();

    if (!token) {
      //  showSnackbar("No Token", SnackbarSeverity.ERROR);
      return undefined;
    }

    // setLoading(true);
    const url = new URL(`${apiConfig.centralGatewayAPIUri}Type?typeName=tourIntervalType`);
    const authString = `Bearer ${token}`;

    const response = fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).then(async (response) => {
      if (response && response.status !== 404) {
        const data = await response.json();


        const dropResult = data.map((entity: any) => {
          return this.createDropboxItemTypesNumbers(entity)
        });

        // console.log(dropResult);

        store.set("dropTourIntervalTypes", dropResult);

        return data;
      }

      return undefined;

    }).catch((err) => {
      // console.log(err)
      return undefined;
    });

    return response;

  }

  public static async fetchTourWeekTypeTypes(): Promise<any[] | undefined> {

    // console.log('fetchTourWeekTypeTypes ...');
    const token = await LoackerService.acquireCentralGatewayToken();

    if (!token) {
      //  showSnackbar("No Token", SnackbarSeverity.ERROR);
      return undefined;
    }

    // setLoading(true);
    const url = new URL(`${apiConfig.centralGatewayAPIUri}Type?typeName=tourWeekType`);
    const authString = `Bearer ${token}`;

    const response = fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).then(async (response) => {
      if (response && response.status !== 404) {
        const data = await response.json();


        const dropResult = data.map((entity: any) => {
          return this.createDropboxItemTypesNumbers(entity)
        });

        // console.log(dropResult);

        store.set("dropTourWeekTypes", dropResult);

        return data;
      }

      return undefined;

    }).catch((err) => {
      // console.log(err)
      return undefined;
    });

    return response;

  }

  public static async fetchNewsTypes(): Promise<any[] | undefined> {

    // console.log('fetchNewsTypes...');
    const token = await LoackerService.acquireCentralGatewayToken();

    if (!token) {
      //  showSnackbar("No Token", SnackbarSeverity.ERROR);
      return undefined;
    }

    // setLoading(true);
    const url = new URL(`${apiConfig.centralGatewayAPIUri}Type?typeName=newsType`);
    const authString = `Bearer ${token}`;

    const response = fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).then(async (response) => {
      if (response && response.status !== 404) {
        const data = await response.json();


        const dropResult = data.map((entity: any) => {
          return this.createDropboxItemTypesStrings(entity)
        });

        // console.log(dropResult);

        store.set("dropNewsTypesTypes", dropResult);

        return data;
      }

      return undefined;

    }).catch((err) => {
      console.log(err)
      return undefined;
    });

    return response;

  }

  public static async fetchEventsTypes(): Promise<any[] | undefined> {

    // console.log('fetchNewsTypes...');
    const token = await LoackerService.acquireCentralGatewayToken();

    if (!token) {
      //  showSnackbar("No Token", SnackbarSeverity.ERROR);
      return undefined;
    }

    // setLoading(true);
    const url = new URL(`${apiConfig.centralGatewayAPIUri}Type?typeName=eventsType`);
    const authString = `Bearer ${token}`;

    const response = fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: authString,
        "Content-Type": "application/json",
      },
      mode: 'cors'
    }).then(async (response) => {
      if (response && response.status !== 404) {
        const data = await response.json();


        const dropResult = data.map((entity: any) => {
          return this.createDropboxItemTypesStrings(entity)
        });

        // console.log(dropResult);

        store.set("dropEventsTypesTypes", dropResult);

        return data;
      }

      return undefined;

    }).catch((err) => {
      console.log(err)
      return undefined;
    });

    return response;

  }



  public static getSitesOptions(): dropItemStringValue[] | undefined {
    const dropData = store.get("dropSite");
    return dropData ? dropData : undefined;
  }

  public static getSuperOrdinatesOptions(): dropItemStringValue[] | undefined {
    const dropData = store.get("dropSuperordinates");
    return dropData ? dropData : undefined;
  }

  public static getCommoditiesBySuperordinates(): dropItemStringValue[] | undefined {
    const dropData = store.get("commoditiesSuperOrdinate");
    return dropData ? dropData : undefined;
  }

  public static getContainerSystemOptions(): dropItemStringValue[] | undefined {
    const dropData = store.get("dropContainerSystem");
    return dropData ? dropData : undefined;
  }

  public static getPriceZoneOptions(): dropItemStringValue[] | undefined {
    const dropData = store.get("dropPriceZone");
    return dropData ? dropData : undefined;
  }

  public static getPrioritiesOptions(): dropItemStringValue[] | undefined {

    return [{ value: "Low", label: "", checked: true }, { value: "Medium", label: "", checked: false }, { value: "High", label: "", checked: false }];
  }

  public static getVehicleGroupsOptions(): dropItemStringValue[] | undefined {
    const dropData = store.get("dropVehicleGroups");
    return dropData ? dropData : undefined;
  }

  public static getCountryCodeGroupsOptions(): dropItemStringValue[] | undefined {

    const dropData = store.get("countryCodeGroups");

    if (!dropData) {

      const data = [{ RowKey: "AT", Label: "AT" }, { RowKey: "DE", Label: "DE" }, { RowKey: "CH", Label: "CH" }, { RowKey: "HU", Label: "HU" }, { RowKey: "SK", Label: "SK" }]

      const dropContainerSystem = data.map((entity: any) => {
        return this.createDropboxItem(entity)
      });

      store.set("countryCodeGroups", dropContainerSystem);

      return dropContainerSystem;
    }
    else {
      return dropData ? dropData : undefined;
    }
  }



  public static getContainerTypesOptions(): dropItemStringValue[] | undefined {
    const dropData = store.get("dropContainerTypes");
    return dropData ? dropData : undefined;
  }

  public static getCommoditiesOptions(): dropItemStringValue[] | undefined {
    const dropData = store.get("dropCommodities");
    return dropData ? dropData : undefined;
  }
  public static getCitiesOptions(): dropItemStringValue[] | undefined {
    const dropData = store.get("dropCities");
    return dropData ? dropData : undefined;
  }

  public static getTourServicessOptions(): dropItemStringValue[] | undefined {
    const dropData = store.get("dropTourServices");
    return dropData ? dropData : undefined;
  }


  public static getTourContainerTypesOptions(): dropItemNumberValue[] | undefined {
    const dropData = store.get("dropTourContainerTypes");
    return dropData ? dropData : undefined;
  }

  public static getTourIntervalTypesOptions(): dropItemNumberValue[] | undefined {
    const dropData = store.get("dropTourIntervalTypes");
    return dropData ? dropData : undefined;
  }

  public static getTourWeekTypesOptions(): dropItemNumberValue[] | undefined {
    const dropData = store.get("dropTourWeekTypes");
    return dropData ? dropData : undefined;
  }

  public static getNewsTypesOptions(): dropItemStringValue[] | undefined {
    const dropData = store.get("dropNewsTypesTypes");
    return dropData ? dropData : undefined;
  }
  public static getEventsTypesOptions(): dropItemStringValue[] | undefined {
    const dropData = store.get("dropEventsTypesTypes");
    return dropData ? dropData : undefined;
  }

}