import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";

import { Theme, createStyles, lighten } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import TableContainer from "@mui/material/TableContainer";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Grid,
} from "@mui/material";

import { WrappedComponentProps } from "../utils/authHelper";
import AuthProvider from "../utils/authProvider";

type AuthProps = WrappedComponentProps;

import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiSwitch-root": {
      margin: theme.spacing(1),
    },
    "& .MuiTypography-root": {
      margin: theme.spacing(1),
    },
  },
  container: {},
  card: {
    padding: "10px",
  },
  table: {
    padding: theme.spacing(2),
    width: "100%",
  },
  diagram: {
    marginTop: 50,
    padding: 30,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

interface IEventsPageProps {}

export const CatalogPage: FunctionComponent<IEventsPageProps> = (props) => {
  const { classes } = useStyles();
  return (
    <Fragment>
      <div className="titleWrapper">
        <h1 className="">Applications Catalog 👷</h1>
      </div>
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={8}>
          <Paper>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>APP</TableCell>
                    <TableCell>DEV</TableCell>
                    <TableCell>TEST</TableCell>
                    <TableCell>RE</TableCell>
                    <TableCell>PROD</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className="heading3"
                      id="webappsonazure"
                      colSpan={5}
                    >
                      <Typography
                        variant="subtitle2"
                        component="span"
                        color="secondary"
                      >
                        Webapps on AZURE{" "}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>Abfuhrkalender</TableCell>
                    <TableCell>
                      <a
                        href="https://gsc-r1-de-ase-abfuhrkalender-1.azurewebsites.net/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <a
                        href="https://abfuhrkalender.genesiscloud.cc/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>Scada Plant</TableCell>
                    <TableCell>{"-"}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <a
                        href="https://scadaplant-at.genesiscloud.cc/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>Digitale Visitenkarte</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <a
                        href="https://me.loacker.cc/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>LandingSite</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <a
                        href="https://contenTableCell elivery.genesiscloud.cc"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>Material Data</TableCell>
                    <TableCell>
                      <a
                        href="https://materialdata.genesiscloud.cc"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <a
                        href="https://materialdata.genesiscloud.cc/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>Product Data Managment (PDM)</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <a
                        href="https://pdm.genesiscloud.cc"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>Stammdaten(MDM)</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <a
                        href="https://mdm.genesiscloud.cc"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>Toolbox</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <a
                        href="https://toolbox.genesiscloud.cc/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>Tours Monitor</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <a
                        href="https://gsc-r1-toursmonitor-at.azurewebsites.net/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="heading3"
                      id="webappsonazure"
                      colSpan={5}
                    >
                      <Typography
                        variant="subtitle2"
                        component="span"
                        color="secondary"
                      >
                        Backends on AZURE
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>Central Gateway API Swagger AAD</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <a
                        href="https://gsc-r1-pr-ase-centralgateway-1.azurewebsites.net/swagger/index.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>External Gateway API Swagger B2C</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <a
                        href="https://gsc-r1-pr-ase-externalcentralgateway-1.azurewebsites.net/swagger/index.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>Material Data API Swagger</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <a
                        href="https://gsc-r1-pr-ase-materialdataapi-1.azurewebsites.net/swagger/index.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>News Data API Swagger</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <a
                        href="https://gsc-r1-pr-ase-newsapi-1.azurewebsites.net/swagger/index.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="heading3"
                      id="webappsonazure"
                      colSpan={5}
                    >
                      <Typography
                        variant="subtitle2"
                        component="span"
                        color="secondary"
                      >
                        Backend on atgolorsdataapi 🚀
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>ERPDataGateway</TableCell>
                    <TableCell>
                      <a
                        href="https://datagatewaybackendde.genesiscloud.cc:4443/graphql"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                    <TableCell>
                      <a
                        href="https://datagatewaybackendte.genesiscloud.cc:4444/graphql"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                    <TableCell>
                      <a
                        href="https://datagatewaybackendre.genesiscloud.cc:4446/graphql"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                    <TableCell>
                      <a
                        href="https://datagatewaybackendpr.genesiscloud.cc:4445/graphql"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <h3 className="heading1" id="mobilapps">
                Mobil Apps 📱
              </h3>
              <ul>
                <li>HandlesApp</li>
              </ul>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid xs={12} sm={3}>
          <Paper>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>APP</TableCell>
                    <TableCell>Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>PM2</TableCell>
                    <TableCell>
                      <a
                        href="https://app.pm2.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Devops</TableCell>
                    <TableCell>
                      <a
                        href="https://dev.azure.com/GenesisCloudApps1/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Azure Portal</TableCell>
                    <TableCell>
                      <a
                        href="https://portal.azure.com/#@genesiscloud.onmicrosoft.com/dashboard/arm/subscriptions/1e24f37d-1083-48ea-827b-0e5dea4461cb/resourcegroups/dashboards/providers/microsoft.portal/dashboards/2cd446d0-5ac4-40d5-bce0-2b5aeff5305f"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Adino</TableCell>
                    <TableCell>
                      <a
                        href="https://srv280.adino.at:8443/login_up.php?success_redirect_url=%2Fsmb%2Fweb%2Fview"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Kunden Portal Dev</TableCell>
                    <TableCell>
                      <a
                        href="https://loacker-kundenportal.denovo.cloud/portal/container-overview"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Kunden Portal</TableCell>
                    <TableCell>
                      <a
                        href="https://kundenportal.loacker.cc/login"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mongo DB</TableCell>
                    <TableCell>
                      <a
                        href="https://cloud.mongodb.com/v2/5f9bd7bca5436a13f75b2c3a#clusters"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Slock Sensors</TableCell>
                    <TableCell>
                      <a
                        href="https://swc.sloc.one/login"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Infeo</TableCell>
                    <TableCell>
                      <a
                        href="https://awm.infeo.at/de/login"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Wordpress Loacker</TableCell>
                    <TableCell>
                      <a
                        href="https://loacker-recycling.com/at/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircleOutlineIcon />
                      </a>
                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                      <TableCell>RECY ONLINE API</TableCell>
                                      <TableCell>
                                          <a
                                              href="http://80.120.126.203:4453/RecyOnline.API/api-docs/index.html"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                          >
                                              <CheckCircleOutlineIcon />
                                          </a>
                                      </TableCell>
                                  </TableRow>
                                  <TableRow>
                                      <TableCell>RECY Import / Export</TableCell>
                                      <TableCell>
                                          <a
                                              href="http://80.120.126.203:4453/bulk-import-export/api-docs/index.html"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                          >
                                              <CheckCircleOutlineIcon />
                                          </a>
                                      </TableCell>
                                  </TableRow>
                                  <TableRow>
                                      <TableCell>RECY businessprocesses</TableCell>
                                      <TableCell>
                                          <a
                                              href="http://80.120.126.203:4453/businessprocesses/api-docs/index.html"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                          >
                                              <CheckCircleOutlineIcon />
                                          </a>
                                      </TableCell>
                                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};
