import React, { FunctionComponent, useEffect, useState } from "react";
import { CircularProgress, Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";

import { TransitionProps } from "@mui/material/transitions";
import Slide, { SlideProps } from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "./loading-indicator";
import { usePrevious } from "../../../hooks/use-previous";
import { makeStyles } from "tss-react/mui";

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

interface IMinimalLoadingIndicatorProps {
  isLoading: boolean;
  initialIndicatorClass?: string;
}

const useStyles = makeStyles()(() => ({
  loadingIndicatorContainer: {
    flex: 1,
  },
}));

export const MinimalLoadingIndicator: FunctionComponent<
  IMinimalLoadingIndicatorProps
> = (props) => {
  const { t } = useTranslation();
  const { isLoading, initialIndicatorClass } = props;
  const { classes } = useStyles();

  const [didInitialLoading, setDidInitialLoading] = useState<boolean>(false);

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (!isLoading && prevIsLoading && !didInitialLoading) {
      setDidInitialLoading(true);
    }
  }, [isLoading, didInitialLoading, prevIsLoading]);

  return (
    <>
      <Snackbar
        open={isLoading && didInitialLoading}
        TransitionComponent={SlideTransition}
      >
        <Alert
          severity="info"
          iconMapping={{ info: <CircularProgress size={20} /> }}
        >
          {t("load_new_data")}
        </Alert>
      </Snackbar>
      {isLoading && !didInitialLoading && (
        <LoadingIndicator
          className={initialIndicatorClass ?? classes.loadingIndicatorContainer}
        />
      )}
    </>
  );
};
