import React, { FunctionComponent } from "react";
import {
  Theme,
  Paper,
  Typography,
  Button,
  useTheme,
  Grid,
  Chip,
  ButtonGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { PageHeading } from "../core/layouts/page-heading";
import { Subheading } from "../core/layouts/subheading";
import { useSnackbar, SnackbarSeverity } from "../../context/SnackbarContext";
import { ClipLoader } from "react-spinners";
import classNames from "classnames";
import { MeQueryResult } from "../../models/types/me";
import { MinimalLoadingIndicator } from "../core/indicator/minimal-loading-indicator";
import { NoDataIndicator } from "../core/no-data-indicator";
import * as _ from "lodash";
import { TapAndPlay } from "@mui/icons-material";
import { LoackerService } from "../../services/service";
import {
  apiConfig,
  tokenRequestCentralgateway,
  tokenRequestErpDataGateway,
  tokenRequestExternalgateway,
} from "../../services/authConfig";
import {
  FullScreenDialog,
  ResponsiveDialog,
} from "../core/dialogs/repsonsive-dialog";
import { ResponsiveDialogContent } from "../core/dialogs/responsive-dialog-content";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    width: 240,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  icon: { width: 60, height: 60, color: theme.palette.secondary.light },
  pw_container: {
    marginTop: theme.spacing(2),
  },
  pw_text: {
    marginTop: theme.spacing(1),
  },
  list: {
    marginTop: theme.spacing(2),
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
}));

interface IMyProfilePageProps {}

function SimpleDialog(props: any) {
  const { onClose, selectedValue, open } = props;

  return (
    <ResponsiveDialog open={open} onClose={onClose} title="Copy token">
      <ResponsiveDialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography color="textSecondary" gutterBottom>
              {selectedValue}
            </Typography>
          </Grid>
        </Grid>
      </ResponsiveDialogContent>
    </ResponsiveDialog>
  );
}

export const MyProfilePage: FunctionComponent<IMyProfilePageProps> = (
  props
) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState<string | undefined>(
    ""
  );

  const meLoading: boolean = false;
  const loading: boolean = false;

  const renderContent = () => {
    const user = LoackerService.getUser();
    if (!loading && !user) {
      return <div />;
    }

    const handleCentralGatewayTokenButtonClick = async () => {
      const response = await LoackerService.tokenSilent(
        tokenRequestCentralgateway
      );
      setSelectedValue(response?.accessToken);
      setOpen(true);
      // console.log(response?.accessToken);
    };

    const handleExternalGatewayTokenButtonClick = async () => {
      const response = await LoackerService.tokenSilent(
        tokenRequestExternalgateway
      );
      setSelectedValue(response?.accessToken);
      setOpen(true);
      // console.log(response?.accessToken);
    };
    const handleERPDataGatewayTokenButtonClick = async () => {
      const response = await LoackerService.tokenSilent(
        tokenRequestErpDataGateway
      );
      setSelectedValue(response?.accessToken);
      setOpen(true);
      // console.log(response?.accessToken);
    };

    const handleClose = () => {
      setOpen(false);
      setSelectedValue("");
    };

    const assignedLocations =
      user?.assignedLocations &&
      _.sortBy(user.assignedLocations, (al) => al.number);

    return (
      <>
        <Paper className={classes.paper}>
          <Grid container justifyContent="space-between">
            <Grid item xs>
              <Subheading title={LoackerService.getUser()?.email || ""} />
              <Grid container direction={"column"} spacing={1}>
                <Grid item xs={12} md={12}>
                  <ProfileValue
                    titleKey={"user.property.roles"}
                    val={user?.roles.toString()}
                  />
                  <ProfileValue
                    titleKey={"user.property.name"}
                    val={`${user?.firstName} ${user?.lastName}`}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  };

  return (
    <div>
      <PageHeading title={t("my_profile.title")} />
      <MinimalLoadingIndicator isLoading={meLoading} />
      {renderContent()}
    </div>
  );
};

const ProfileValueList: FunctionComponent<{
  titleKey: string;
  values: string[];
}> = ({ titleKey, values }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <Grid item xs={12} md={true} className={classes.list}>
      <Grid container>
        <Grid item xs={12}>
          <Typography>
            <b>{t(titleKey)}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.chipContainer}>
          {values.map((v) => (
            <Chip key={v} label={v} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const ProfileValue: FunctionComponent<{
  titleKey: string;
  val?: string | null;
}> = ({ titleKey, val }) => {
  const { t } = useTranslation();
  return (
    <Grid item>
      <Grid container spacing={2}>
        <Grid item>
          <Typography>
            <b>{t(titleKey)}: </b>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{val}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
