export const appName = process.env.REACT_APP_NAME;
export const appDesc = process.env.REACT_APP_DESC;
export const version = process.env.REACT_APP_VERSION;
export const port = process.env.PORT;
export const nodeEnv = process.env.NODE_ENV;
export const clientId = process.env.REACT_APP_CLIENTID;
export const authority = process.env.REACT_APP_AUTHORITY;
export const redirectUri = process.env.REACT_APP_REDIRECT_URI;
export const centralGatewayAPIUri = process.env.REACT_APP_RESOURCE_URI + "api/";
export const centralGatewayUri = process.env.REACT_APP_RESOURCE_URI;
export const scopeCentralGateway = process.env.REACT_APP_RESOURCE_URI;
export const scopeExternalGateway =
  process.env.REACT_APP_RESOURCE_SCOPE_EXTERNALGATEWAY;
export const scopeERPDataGateway =
  process.env.REACT_APP_RESOURCE_SCOPE_ERPDATAGATEWAY;
