 
import { Configuration,AccountInfo} from "@azure/msal-browser";

import {clientId,authority,redirectUri,centralGatewayAPIUri,scopeERPDataGateway,scopeCentralGateway,scopeExternalGateway} from "../config"

import {Request} from "./authTypes"
// For a full list of msal.js configuration parameters,
// visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
export const msalConfig  = {
  auth: {
    clientId: clientId as string,
    authority:authority as string,
    redirectUri:redirectUri as string
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
} as Configuration;

// Coordinates and required scopes for your web api
export const apiConfig = {
     //resourceUri: 'https://gsc-r1-pr-ase-adminserviceapi-1.azurewebsites.net/api/',
     // resourceUri: 'https://gsc-r1-de-ase-adminserviceapi-1.azurewebsites.net/api/',
     centralGatewayAPIUri: centralGatewayAPIUri as string,
     scopeCentralGateway: scopeCentralGateway as string,
     scopeERPDataGateway: scopeERPDataGateway as string,
     scopeExternalGateway: scopeExternalGateway as string,

};

/**
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
export const loginRequest = {
  scopes: ["openid"],
};

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequestCentralgateway = {
  name:'CentralGateway',
  scopes: [apiConfig.scopeCentralGateway],
  account:{} as AccountInfo,
  forceRefresh: false
} as Request;

export const tokenRequestExternalgateway = {
  name:'ExternalGateway',
  scopes: [apiConfig.scopeExternalGateway],
  account:{} as AccountInfo,
  forceRefresh: false
} as Request;

export const tokenRequestErpDataGateway = {
  name:'ERPDataGateway',
  scopes: [apiConfig.scopeERPDataGateway],
  account:{} as AccountInfo,
  forceRefresh: false
} as Request;
 