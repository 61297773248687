import { Entity, ITableEntity } from "./Entity";

export interface ReceiveDigidoOrderResponseAction {
  type: "ORDER_RESPONSE";
  results: IDigidoTransactionResponse[];
}

export type IDigidoTransactionResponse = {
  date: string;
  senderIdentifier: string;
  transactionId: string;
  responseLog: string;
  responseConfirmation: string;
  responseError: string;
  responseCode: number;
  responseValue: string[];
  weightNoteNumber: string;
  sendedData: string;
}


export class DigidoTransactionResponseEntity implements IDigidoTransactionResponse {
  __typename: string = "DigidoTransactionResponseEntity";
  date = "";
  senderIdentifier = "";
  transactionId = "";
  responseLog = "";
  responseConfirmation = "";
  responseError = "";
  responseCode = 0;
  responseValue = [""];
  weightNoteNumber = "";
  sendedData = "";

  constructor(init?: Partial<DigidoTransactionResponseEntity>) {
    Object.assign(this, init);
  }
}

export const createDigidoTransactionResponseEntity = (entity: any): DigidoTransactionResponseEntity => {
  return new DigidoTransactionResponseEntity({
      date: entity.Date ? entity.date:"",
      senderIdentifier: entity.senderIdentifier ? entity.senderIdentifier : "",
      transactionId: entity.transactionId ? entity.transactionId : "",
      responseLog: entity.responseLog ? entity.responseLog : "",
      responseConfirmation: entity.responseConfirmation ? entity.responseConfirmation : "",
      responseError: entity.responseError ? entity.responseError : "",
      responseCode: entity.responseCode ? entity.responseCode : 0,
      weightNoteNumber: entity.weightNoteNumber ? entity.weightNoteNumber : "",
      responseValue: entity.responseValue ? entity.responseValue : [""],
      sendedData: entity.sendedData ? entity.sendedData : "",
  });
};


export type DigidoTransactionResponseData = IDigidoTransactionResponse ;