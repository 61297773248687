import React, { useState } from "react";
import BinListLeft from "./BinListLeft";
import BinListRight from "./BinListRight";
import BinList from "./BinListRight";
import GarbageItem, { GarbageItemProps } from "./GarbageItem";

interface GarbageListProps {
  garbageItems: GarbageItemProps[] | undefined;
  onCorrectBinClick: (garbageType: string, assert: boolean) => void;
}

const Board: React.FC<GarbageListProps> = ({
  garbageItems,
  onCorrectBinClick,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleBinClick = (garbageType: string) => {
    if (garbageItems === undefined) {
      return;
    }

    // if (garbageItems[currentIndex].type === garbageType) {
    //   onCorrectBinClick(garbageType);
    //   setCurrentIndex(currentIndex + 1);
    // }
    const id = garbageItems[currentIndex].name;
    const assert =
      garbageItems[currentIndex].type === garbageType ? true : false;
    onCorrectBinClick(id, assert);
    setCurrentIndex(0);
  };

  return (
    <div className="board">
      <div>
        {garbageItems && garbageItems.length >= 1 && (
          <BinListLeft onBinClick={handleBinClick} />
        )}
      </div>
      <div>
        {garbageItems && garbageItems[currentIndex] && (
          <GarbageItem
            number={garbageItems[currentIndex].number}
            name={garbageItems[currentIndex].name}
            type={garbageItems[currentIndex].type}
          />
        )}
      </div>
      <div>
        {garbageItems && garbageItems.length >= 1 && (
          <BinListRight onBinClick={handleBinClick} />
        )}
      </div>
    </div>
  );
};

export default Board;
