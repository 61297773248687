import React, { FunctionComponent, useContext, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Theme, createStyles, lighten } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import remarkGfm from 'remark-gfm'
import { Grid, Paper, Container } from "@mui/material";

import { WrappedComponentProps } from "../utils/authHelper";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
  },
  container: {
    width: "100%",
    position: "relative",
  },
  card: {},
  table: {
    padding: theme.spacing(2),
    width: "100%",
    marginTop: "20px",
    "& img": {
      width: "100%",
    },
  },
  diagram: {
    marginTop: 50,
    padding: 30,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  img: {
    widh: "250px",
    borderRadius: "8px",
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

interface IEventsPageProps {}
export const HandelsappPage: FunctionComponent<IEventsPageProps> = (props) => {
  const { classes } = useStyles();
  const [markdown, setMarkdown] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (markdown === "") {
      setLoading(true);

      import(`../docs/Handelsapp.md`)
        .then((res) => {
          fetch(res.default)
            .then((res) => res.text())
            .then((res) => {
              setMarkdown(res);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  });

  return (
    <Paper className={classes.table}>
      {loading && <CircularProgress color="primary" />}
          <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]}  />
    </Paper>
  );
};
