import { IntervalType, WeekType } from "../models/types/graphql-global-types";

export const de = {
  login_error: {
    generic: "Ein Fehler ist aufgetreten. Bitte überprüfen Sie Ihre Login-Daten und versuchen Sie es erneut.",
    invalid_credentials: "Login fehlgeschlagen, bitte überprüfen Sie Ihre Zugangsdaten",
    user_deactivated: "Ihr User wurde deaktiviert, bitte wenden Sie sich an Ihren Betreuer",
  },
  feature_in_progress:
    "Dieses Feature befindet sich derzeit noch in Entwicklung und wird Ihnen in Kürze zur Verfügung stehen!",
  loading: "Lade Daten...",
  preview: "Vorschau",
  download: "Download",
  search_result: "Suchergebnis",
  actions: "Aktionen",
  show_more: "Mehr Anzeigen",
  show_less: "Weniger Anzeigen",
  from: "von",
  to: "bis",
  required_field: "* Pflichtfeld",
  close: "Schließen",
  cancel: "Abbrechen",
  save: "Speichern",
  confirm: "Bestätigen",
  create: "Erstellen",
  recordCreate: "Neu",
  recordEdit: "Bearbeiten",
  recordDelete: "Löschen",
  selectedfrom: "Elemente Ausgewählt aus",
  filter: "Filter Results",
  addremove: "Verfügbare Artikel hinzufügen / entfernen",
  email: "E-Mail",
  password: "Passwort",
  coming_soon: "Coming soon!",
  load_more: "Mehr Laden",
  delete: "Löschen",
  continue: "weiter",
  back: "zurück",
  customer: "Kunde",
  login: "Anmelden",
  login_en: "Login",
  back_to: "Zurück zum",
  password_forgotten: "Passwort vergessen",
  password_reset: "Passwort zurücksetzen",
  password_change: "Passwort ändern",
  password_change_text: "Sie erhalten einen Link per E-Mail, mit dem Sie Ihr Passwort ändern können.",
  set_password: "Passwort setzen",
  confirm_password: "Passwort bestätigen",
  for: "für",
  edit: "Ändern",
  search: "Suchen...",
  role: "Rolle",
  no_data: "Keine Daten gefunden",
  size: "Größe",
  date: "Datum",
  status: "Status",
  success: "Erfolg",
  load_new_data: "Lade Daten...",
  generic_error: "Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut!",
  description: "Beschreibung",
  example_image: "Symbolbild",
  weighing_note: "Wiegescheine",
  photo: "Fotos",
  login_page: {
    please_enter_email: "Geben Sie eine E-Mail-Adresse ein",
    please_enter_valid_email: "Geben Sie eine gültige E-Mail-Adresse ein",
    please_enter_password: "Geben Sie ein Passwort ein",
    accept_agb:
      "Ich habe die <0>AGB</0> und die <1>Datenschutzerklärung</1> gelesen und erkläre mich damit einverstanden",
  },
  no_options: "Keine Auswahlmöglichkeiten",
  container_action: {
    COLLECT: "Abholen",
    EMPTY_CONTAINER: "Ausleeren",
    EXCHANGE: "Austauschen",
    number_of_containers: "Stück",
    success: "Aktion wurde erfolgreich ausgeführt!",
    error: "Beim Ausführen der Aktion ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
    container_amount_info_singular: "Momentan ist Ihnen {{amount}} Behälter diesen Typs und Sorte zugewiesen.",
    container_amount_info_plural: "Momentan sind Ihnen {{amount}} Behälter diesen Typs und Sorte zugewiesen.",
  },
  navigation: {
    home: "HOME",
    user_page: "Benutzerverwaltung",
    my_profile: "Mein Profil",
    contact: "Kontakt",
    architecture: "Architecture",
    info: "Info",
    central: "Central Gateway",
    external: "External Gateway",
    atgolorsdataapi: "Atgolorsdataapi",
    handelsapp: "Handelsapp",
    azure: "Azure",
    auth: "Auth",
    digido:"DIGIDO-ARA"
  },
  business_client_overview: {
    title: "Gewerbekunde auswählen",
    description: "Bitte wählen Sie den Gewerbekunden aus, für den Sie Behälter bzw. Anfahrstellen sehen möchten.",
    description_search:
      "Bitte geben Sie die Personenkonto Nummer des Gewerbekunden ein, für den Sie Behälter bzw. Anfahrstellen sehen möchten.",
    navigate_to_container_overview: "Behälterübersicht",
    table: {
      number: "Personenkonto Nummer",
      search_name: "Personenkonto Name",
      search_account_number: "Personenkonto Nummer",
      name: "Gewerbekunde",
    },
    select: "Auswählen",
    search: "Gewerbekundensuche",
  },
  complain_dialog: {
    complains: {
      placeholder: "Bitte Kategorie auswählen",
      incorrect_container: "Fehlerhafter Behälterstand",
      damage: "Beschädigung melden",
      relocate_container: "Umstellen des Behälters beantragen",
      other_commodity: "Andere Sorte melden",
      other: "Sonstiges",
    },
    category: "Kategorie",
  },
  footer: {
    imprint: "Impressum",
    data_policy: "Datenschutzerklärung",
    terms_and_conditions: "AGB",
  },
  contact: {
    and: " und ",
    title: "Loacker Recycling",
    subtitle: "Kontaktmöglichkeiten",
    info_email1: "Gerne können Sie uns eine E-Mail mit Ihrem Anliegen an ",
    info_email2: " schicken.",
    info_time_telnumber1: "Montag bis Freitag zwischen ",
    info_time_telnumber2: " sind wir telefonisch unter der Nr. ",
    info_time_telnumber3: " für Sie erreichbar.",
  },
  table: {
    data_loading: "Daten werden geladen",
    no_data: "Keine Daten verfügbar",
    previous: "Zurück",
    next: "Weiter",
    page: "Seite",
    rows: "Einträge",
    of: "von",
  },
  file_drop_zone: {
    upload: {
      move_files: "Foto hierher ziehen oder",
      select_files: "klicken für Auswahl",
      drop_files: "Foto hier ablegen",
      uploaded_documents: "Hochegeladene Fotos",
      error_file_selector:
        "Bitte wählen Sie eine Datei im Format JPG oder PNG, dessen Dateigröße maximal 10 MB beträgt!",
      error_only_one_file_allowed: "Bitte wählen Sie nur ein Foto aus!",
    },
  },
  tour: {
    id: "ID",
    number: "Tour",
    sequence: "Sequenz",
    amount: "Anzahl",
    status: "Status",
    weekday: "Wochentag",
    week_type: "Wochentyp",
    interval_type: "Zyklus",
    interval_times: "Intervall",
    setting: "Einstellung",
    active: "aktiv",
    inactive: "inaktiv",
    valid_from: "Gültig ab",
    valid_until: "Gültig bis",
    last_date: "Letzte Leerung",
    next_date: "Nächste Leerung",
    setting_description_weektype: "{{weekType}} Woche",
    nesting2: "blub",
    every: {
      [`${IntervalType.DAILY}`]: "Jeden",
      [`${IntervalType.MONTHLY}`]: "Jden",
      [`${IntervalType.WEEKLY}`]: "Jede",
      [`${IntervalType.QUARTERLY}`]: "Jedes",
    },
    setting_description: "$t(tour.every.{{intervalType}}) {{intervalTypeDescription}}",
    setting_description_plural: "$t(tour.every.{{intervalType}}) {{intervalTimes}}. {{intervalTypeDescription}}",
    mo: "Mo",
    di: "Di",
    mi: "Mi",
    do: "Do",
    fr: "Fr",
    sa: "Sa",
    so: "So",
  },
  interval_type_description: {
    [`${IntervalType.DAILY}`]: "Tag",
    [`${IntervalType.DAILY}_plural`]: "Tage",
    [`${IntervalType.WEEKLY}`]: "Woche",
    [`${IntervalType.WEEKLY}_plural`]: "Wochen",
    [`${IntervalType.MONTHLY}`]: "Monat",
    [`${IntervalType.MONTHLY}_plural`]: "Monate",
    [`${IntervalType.YEARLY}`]: "Jahr",
    [`${IntervalType.YEARLY}_plural`]: "Jahre",
    [`${IntervalType.QUARTERLY}`]: "Quartal",
    [`${IntervalType.QUARTERLY}_plural`]: "Quartal",
  },
  interval_times: {
    WEEKLY: "wöchentlich",
    MONTHLY: "monatlich",
    QUARTERLY: "vierteljährlich",
  },
  week_type_description: {
    [`${WeekType.CW}`]: "KW",
    [`${WeekType.EVEN_CW}`]: "gerade KW",
    [`${WeekType.UNEVEN_CW}`]: "ungerade KW",
  },
  week_type: {
    EVEN: "gerade",
    ODD: "ungerade",
  },
  weekday: {
    MONDAY: "Montag",
    TUESDAY: "Dienstag",
    WEDNESDAY: "Mittwoch",
    THURSDAY: "Donnerstag",
    FRIDAY: "Freitag",
    SATURDAY: "Samstag",
    SUNDAY: "Sonntag",
  },
  password_reset_page: {
    password_forgotten_info: "Bitte geben Sie Ihre E-Mail-Adresse ein, um ein neues Passwort anzufordern",
    password_reset_subheading: "Bitte geben Sie Ihr neues Passwort ein",
    password_reset_info:
      "Das Passwort muss aus mindestens 6 Zeichen, einer Zahl und einem Groß- und einem Kleinbuchstaben bestehen",
    mail_sent: "Überprüfen Sie Ihr E-Mail-Postfach",
    user_not_found_error: "Es ist ein Fehler aufgetreten, überprüfen Sie Ihre Eingaben",
    password_changed: "Ihr Passwort wurde erfolgreich geändert!",
    api_error: "Es ist ein Fehler aufgetreten",
  },
  my_profile: {
    title: "Mein Profil",
    settings: "Einstellungen",
  },
  timeframe: {
    timeframe: "Zeitraum",
    ALL_DAY: "Ganztags",
    MORNING: "Vormittag",
    NOON: "Nachmittag",
  },
  user: {
    property: {
      first_name: "Vorname",
      last_name: "Nachname",
      name: "Name",
      email: "E-Mail-Adresse",
      tel_nr: "Tel Nr.",
      user_type: "Type",
      roles: "Rolle",
      position: "Funktion",
      loacker_company: "Loacker Firma",
      last_login: "Letzter Login",
      assigned_business_clients: "Personenkonten",
      assigned_business_client: "Personenkonto",
      assigned_locations: "Anfahrstellen",
    },
  },
  adittionalText: {
    property: {
      application: "application",
      usage: "usage",
      countryCode: "Landesvorwahl",
      text: "text"
    },
  },
  user_page: {
    heading: "Benutzerverwaltung",
    text:
      "Hier sind alle bisher angelegten Benutzer aufgelistet. Sie können die bestehenden User bearbeiten oder über den Button neue anlegen.",
    add_user: "Benutzer hinzufügen",
    empty_business_client_selection: "Alle",
    user_edited: "Benutzer erfolgreich bearbeitet",
    user_created: "Benutzer angelegt",
    account_not_found: "Personenkonto nicht gefunden",
    no_accounts_selected: "Kein Personenkonto ausgewählt",
    success: {
      user_deleted: "Benutzer erfolgreich gelöscht",
    },
    errors: {
      could_not_load_loacker_companies: "Loacker Firmen konnten nicht geladen werden",
      could_not_load_business_clients: "Personenkonten konnten nicht geladen werden",
      could_not_load_locations: "Anfahrstellen konnten nicht geladen werden",
      could_not_load_users: "Benutzer konnten nicht geladen werden",
      could_not_update_user: "Benutzer konnte nicht aktualisiert werden",
      could_not_create_user: "Benutzer konnte nicht erstellt werden",
      business_client_not_found: "Kein Personenkonto mit dieser Nummer gefunden",
      business_client_exists: "Personenkonto mit dieser Nummer wurde bereits hinzugefügt",
    },
    actions: {
      edit: "Bearbeiten",
      delete: "Löschen",
      activate: "Aktivieren",
      deactivate: "Deaktivieren",
    },
    delete_dialog: {
      heading: "Löschen",
      text: "Möchten Sie den User {{email}} löschen?",
      confirm_text: "Löschen",
    },
    edit_dialog: {
      edit_heading: "Benutzer bearbeiten",
      create_heading: "Benutzer erstellen",
    },
  },
  additionaltext_page: {
    heading: "Additionaltext management",
    text:
      "Hier sind alle Text infos fur besonderes websites als HTML gespeichert",
    add_user: "Benutzer hinzufügen",
    empty_business_client_selection: "Alle",
    user_edited: "Benutzer erfolgreich bearbeitet",
    user_created: "Benutzer angelegt",
    account_not_found: "Personenkonto nicht gefunden",
    no_accounts_selected: "Kein Personenkonto ausgewählt",
    success: {
      user_deleted: "Benutzer erfolgreich gelöscht",
    },
    errors: {
      could_not_load_loacker_companies: "Loacker Firmen konnten nicht geladen werden",
      could_not_load_business_clients: "Personenkonten konnten nicht geladen werden",
      could_not_load_locations: "Anfahrstellen konnten nicht geladen werden",
      could_not_load_users: "Benutzer konnten nicht geladen werden",
      could_not_load_news: "Benutzer konnten nicht geladen werden",
      could_not_update_user: "Benutzer konnte nicht aktualisiert werden",
      could_not_create_user: "Benutzer konnte nicht erstellt werden",
      business_client_not_found: "Kein Personenkonto mit dieser Nummer gefunden",
      business_client_exists: "Personenkonto mit dieser Nummer wurde bereits hinzugefügt",
    },
    actions: {
      edit: "Bearbeiten",
      delete: "Löschen",
      activate: "Aktivieren",
      deactivate: "Deaktivieren",
    },
    delete_dialog: {
      heading: "Löschen",
      text: "Möchten Sie den User {{email}} löschen?",
      confirm_text: "Löschen",
    },
    edit_dialog: {
      edit_heading: "Benutzer bearbeiten",
      create_heading: "Benutzer erstellen",
    },
  },
  tours_page: {
    heading: "Tours management",
    dashboard: "Tours Dashboard",
  },
  holidays_page: {
    heading: "Holidays management",
    text: "Region Feiertage, Berechnung des nächsten Datums",
    writeText: "Schreibt ein"
  },
  pricezones_page: {
    heading: "Price Zones management",
    dashboard: "Price Zones Dashboard",
  },
  dispotours_page: {
    heading: "DispoTours management",
    text: "DispoTours im Abfuhrkalender",
    writeText: "Schreibt ein"
  },
  commodities_page: {
    dashboard: "Sorten Dashboard",
    heading: "Sorten",
    writeText: "Schreibt ein",
    tabs: {
      intro: "info",
      commodities: "Material Datenbank",
      locations: "Beziehung Standorte",
      carbonEmmision: "CarbonEmmision",
      apps: "Anwendung",
      customers: "Kunden",
      companies: "Firmen",
    }
  },
  containerTypes_page: {
    dashboard: "Behältertypen Dashboard",
    heading: "Behältertypen",
    writeText: "Schreibt ein",
    tabs: {
      intro: "info",
      containerTypes: "Behältertypen",
      vehicleGroups: "Beziehung Fahrzeuggruppen",
      tourServices: "Beziehung Dienstleistungen",
      cotntainerSystems: "Beziehung Behältersystem",
      commodities: "Beziehung Bevorzugte Sorten",
      commoditiesNegative: "Beziehung Nicht erlaubte Sorten",

    }
  },
  sites_page: {
    heading: "Standorte",
    text: "Standorte Catalogue",
    writeText: "Schreibt ein"
  },
  userrequest_page: {
    dashboard: "Benutzeranfrage Dashboard",
    heading: "Zugang",
    text: "Eintritt/Austritt",
    writeText: "Führungskraft Anfrage Eintritt/Austritt"
  },
  vehiclegroups_managment: {
    heading: "Fahrzeuggruppen",
    text: "Fahrzeuggruppen Catalogue",
    writeText: "Schreibt ein"
  },
  tourservice_page: {
    heading: "Dienstleistungen",
    text: "Dienstleistungen Catalogue",
    writeText: "Schreibt ein"
  },
  containerSystems_page: {
    heading: "Behältersystem",
    text: "Behältersystem Catalogue",
  },
  superordinates_page: {
    heading: "Sortengruppenzuordnung",
    text: "Sorten Gruppen Catalogue",
  },
  news_page: {
    dashboard: "News & Events Dashboard",
    heading: "News management",
    text: "News Catalogue",
    writeText: "Schreibt ein!"
  },
  events_page: {
    heading: "Events management",
    text: "Events Catalogue",
    writeText: "Schreibt ein!"
  },
  files_page: {
    heading: "Dateiverwaltung",
    text: "Dateiverwaltung im Azure",
    writeText: "Inhalte hochladen ",
    containerList: "Aktuelle Container in der Cloud ",
    files: "Inhalte hochladen ",
    upload: "Hochladen ",
    download: "Herunterladen ",
    delete: "Löschen"
  },
  tourInfoCity_page: {
    heading: "Tours Info Cities management",
    text: "Extra Info im Abfuhrkalender",
    writeText: "Schreibt ein"
  },
  zones_page: {
    heading: "Zones management",
    text: "Preiszonen im Containershop verwendete",
    writeText: "Schreibt ein"
  },
  cities_page: {
    heading: "Städte management",
    text: "Preiszonen für Städte zuordnen.",
    writeText: "Schreibt ein"
  },
  priceZoneCommodities_page: {
    heading: "Price zones Commodities management",
    text: "Preiszonen für Sorten zuordnen.",
    writeText: "Schreibt ein"
  },
  logupdate_page: {
    heading: "Activity Logs",
    text: "delete this records after you updated the data on Recy",
    writeText: "delete this records after you updated the data on Recy"
  },
  priceZoneContainerTypes_page: {
    heading: "Price zones ContainerTypes management",
    text: "Preiszonen für Behälter Typen zuordnen.",
    writeText: "Schreibt ein"
  },
  tourInfoCity: {
    property: {
      partitionKey: "PartitionKey",
      rowKey: "RowKey",
      isActive: "IsActive",
      countryCode: "Landesvorwahl",
      zipCode: "ZipCode",
      zipText: "Text"
    },
  },
  logUpdate: {
    property: {
      partitionKey: "PartitionKey",
      rowKey: "RowKey",
      isActive: "IsActive",
      changes: "Changes",
    },
  },
  holiday: {
    property: {
      partitionKey: "Region",
      regions: "Region",
      holidayName: "Feiertagsname",
      holidayDate: "Feiertagsdatum",
      countryCode: "Landesvorwahl",
      monthNumber: "Monat Nummernbasiert 0, Januar ist 0.",
      dayNumber: "Tagesnummer",
      year: "Jahr",
      isActive: "Aktive"
    },
  },
  dispotour: {
    property: {
      partitionKey: "partitionKey",
      rowKey: "rowKey",
      created: "created",
      timestamp: "timestamp",
      creator: "creator",
      modifier: "modifier",
      place: "Ort",
      isActive: "is Aktive",
      zipCode: "Postleitzahl",
      tourWeekType: "Wochen art",
      countryCode: "Landesvorwahl",
      superordinate: "Hauptgruppenzuordnung",
      canShift: "canShift",
      comment: "kommentar",
      id: "id",
      intervalTimes: "Intervall Anzahl",
      tourContainerType: "Behelter Type",
      tourIntervalType: "Interval Art",
      weekday: "Wochentag",
      rowKeyTourContainerTypeName: "rowKeyTourContainerTypeName",
      rowTourIntervalTypeName: "rowTourIntervalTypeName",
      rowKeyTourWeekTypeName: "rowKeyTourWeekTypeName"
    },
  },
  news: {
    property: {
      application: "application",
      countryCode: "Landesvorwahl",
      statusText: "status",
      isActive: "isActive",
      groupName: "groupName",
      companyNumber: "company Number",
      newsType: "newsType",
      titel: "titel",
      modifierName: "modifierName",
      accountNumbers: "account numbers",
      locationNumbers: "location numbers",
      roles: "user roles",
      originApplication: "origin application",
      originCompanyNumber: "origin companyNumber",
      text: "text",
      link: "link",
      validFrom: "valid from",
      validTill: "valid till",
    }
  },
  events: {
    property: {
      application: "App",
      countryCode: "Land",
      isActive: "Aktive",
      statusText: "Status",
      text: "Text",
      titel: "Titel",
      modifierName: "ModifierName",
      link: "link",
      groupName: "Gruppenname",
      eventDate: "Datum der Veranstaltung",
      eventsType: "Art der Veranstaltung",
      place: "Standort der Veranstaltung",
      cities: "Eingeladene Standorte",
      attendees: "Teilnehmer",
      attendeesCount: "Teilnehmer zählen",
      roles: "Eingeladene Benutzerrollen",
    }
  },
  commodity: {
    property: {
      partitionKey: "Sorte",
      rowKey: "Type",
      customer: "Kunde",
      application: "Anwendung",
      countryCode: "Land",
      isActive: "Aktive",
      company: "Firma",
      companyNumber: "Firmen N",
      number: "Nummer",
      type: "Type",
      name: "Name",
      saving: "CO2 Spar",
      currency: "Währung",
      alias: "Alias",
      recyclingPrice: "Recycling Price",
      unit: "Einheit",
      containerUnit: "Gebinde/Pack-Einheit",
      containerSize: "Gebindegröße/Packet-Gewicht",
      density: "Dichte Lose",
      densityPressed: "Dichte gepresst",
      container: "Behälter",
      groupName: "Hauptgruppenzuordnung",
      groupNumber: "Hauptgruppenzuordnung nummer",
      branch: "Spartenzuordnung",
      superOrdinateName: "Hauptgruppenzuordnung",
      superOrdinate: "Super Ordinate",
      eu: "Europäischer Abfallkatalog",
      national: "Ö-Norm",
      commodityDescription: "Beschreibung",
      mandatoryNote: "Begleitscheinpflicht",
      mandatoryNoteAT: "Begleitschein AT",
      mandatoryNoteCH: "Begleitschein CH",
      mandatoryNoteD: "Begleitschein D",
      allowedContent: "Was gehört dazu",
      forbiddenContent: "Was gehört keinesfalls dazu",
      uriImgPrincipal: "Principal bild",
      uriImgSecondary: "Secondary bild",
      uriImgAnalysisResult: "Analysis Result",
      basler: "Basler Code",
      oecd: "OECD Code",
      gtin: "GTIN Code",
      spezCode: "SpezC",
      gefahrCode: "GefharC",
      veva: "VEVA Code",
      recovery: "Beseitigungs (D)/Verwertungs(R)",
      treatmentFacility: "TreatmentFacility",
      adr: "ADR-Kennenzeichnung",
      dangerousMeterial: "ADR",
      needsApproval: "Notifizierung",
      ak: "ak",
      intrastat: "Instrastat / Zolltarifnummer",
      hints: "Hinweise",
      comment: "Weiterführende Informationen",
      unClass: "UN Class",
      unNumber: "UN Number",
      departmentLocation: "Department Location",
      containerAssigned: "Container Assigned",
      warehouseAssigned: "Warehouse Assigned",
      packingRegulation: "Packing Regulation",
      textModule: "TextModule",
      elv: "ELV",
      profitcenterSAP: "ProfitcenterSAP",
      profitcenterSAP2: "ProfitcenterSAP2",
      goodsNumber: "Goods Number",
      iRecy: "IRecy",
      keywords: "Keywords",
      isDispoCommoditie: "Is Dispo Commoditie",
    },
  },
  containerType: {
    property: {
      partitionKey: "Behälter",
      id: "ID",
      rowKey: "Type",
      countryCode: "Landesvorwahl",
      isActive: "Aktive",
      company: "Firmen N",
      companyNumber: "Firmen N",
      type: "Type",
      name: "Name",
      alias: "Alias",
      description: "Bemerkung",
      additionalPrice: "Zusätzlicher Preis",
      height: "Höhe",
      length: "Länge",
      recyUUID: "Recy UUID",
      rentalDays: "Miettage",
      rentalPerDayFromX: "Miettage From",
      rentalPerDayTillX: "Miettage Till",
      volume: "Volumen",
      weight: "Gewicht",
      maxWeight: "Maximales gesamtgewicht",
      width: "Breite",
      size: "Größe",
      uriImgPrincipal: "Bild URI",
      activityCollect: "Activity Collect",
      activityDuplicateAllow: "Activity DuplicateAllow",
      activityExchange: "Activity Exchange",
      activityPlacementAllow: "Activity PlacementAllow",
      category: "category",
      number: "number",
      containerSystem: "Behältersystem Nummer",
      containerSystemName: "Behältersystem",
      tourServiceName: "Dienstleistungen",
      vehicleGroup: "Fahrzeuggroup",
      vehicleGroupName: "Fahrzeuggroup Name",
      vehicleGroupDescription: "Fahrzeug Bemerkung",
      containerCount: "Fahrzeug Stuck",
      residual: "Gewicht residual",
      building: "Gewicht building",
      wood: "Gewicht wood",
      construction: "Gewicht construction",
      green: "Gewicht green",
    },
  },
  superordinate: {
    property: {
      rowKey: "Nummer",
      countryCode: "Landesvorwahl",
      abbrSuperOrdinate: "Abkürzung",
      colorCode: "Farbcode",
      label: "Etikett",
      alias: "Alias",
      description: "Bezeichnung",
      processing: "Verwertung",
      examples: "Beispiele",
      isBusinessCustomer: "Ist Geschäftskunde",
      orderBy: "Sortieren nach",
      isActive: "Is Aktiv",
    }
  },
  vehiclegroup: {
    property: {
      partitionKey: "Landesvorwahl",
      rowKey: "Nummer",
      companyNumber: "Firmen N",
      description: "Bemerkung",
      label: "Name",
      isActive: "Is Aktiv",
    }
  },
  tourservice: {
    property: {
      partitionKey: "Landesvorwahl",
      rowKey: "rowKey",
      companyNumber: "Firmen N",
      description: "Bemerkung",
      label: "Name",
      isActive: "Is Aktiv",
    }
  },
  pricezone: {
    property: {
      partitionKey: "pricezone",
      countryCode: "Landesvorwahl",
      rowKey: "Nummer",
      companyNumber: "Firmen N",
      description: "Bemerkung",
      label: "Name",
      isActive: "Is Aktiv",
    }
  },
  city: {
    property: {
      partitionKey: "city",
      countryCode: "Landesvorwahl",
      rowKey: "Nummer",
      companyNumber: "Firmen N",
      description: "Bemerkung",
      zipcode: "ZipCode",
      place: "Place",
      region: "Region",
      transportationPriceZone: "Price Zone",
      priceIncrease: "Price Increase",
      isActive: "Is Aktiv",
    }
  },
  priceZoneCommodity: {
    property: {
      partitionKey: "Zone",
      rowKey: "Rowkey",
      entity: "Commodity",
      companyNumber: "Firmen N",
      description: "Bemerkung",
      label: "Alias",
      isActive: "Is Aktiv",
    }
  },
  priceZoneContainer: {
    property: {
      partitionKey: "Zone",
      rowKey: "Rowkey",
      entity: "Container Type",
      companyNumber: "Firmen N",
      description: "Bemerkung",
      transportationCost: "Transportation Cost",
      label: "Alias",
      isActive: "Is Aktiv",
    }
  },
  containerSystem: {
    property: {
      partitionKey: "Landesvorwahl",
      rowKey: "Nummer",
      companyNumber: "Firmen N",
      description: "Bemerkung",
      label: "Name",
      isActive: "Is Aktiv",
    }
  },
  site: {
    property: {
      partitionKey: "Landesvorwahl",
      rowKey: "Nummer",
      companyNumber: "Firmen N",
      description: "Bemerkung",
      label: "Etikett",
      orderBy: "OrderBy",
      lat: "Lat",
      long: "Long",
      city: "City",
      street: "Street",
      zipcode: "Zipcode",
      isActive: "Is Aktiv",
      isMainSite: "Is Haupt Site",
      uriImgPrincipal: "Bild URI",
      emailSignature: "Email Signature",
      contactEmail: "Contact Email",
      contactPhoneNumber: "Contact PhoneNumber",
      contactFaxNumber: "Contact FaxNumber",
      countryCode: "Landesvorwahl",
      currency: "Währung",
      customerServiceEmail: "Email des Kundencenter",
      customerServicePhoneNumber: "Telefonnummer des Kundencenter",
      director: "Geschäftsführer",
      legalAuthority: "Zuständige Behörde",
      department: "Firmengericht",
      extraLegalInfo: "Extra Legal Info",
      legalPropouse: "Gewerbeberechtigungen aufrecht",
      disclosure: "Angaben zur Online-Streitbeilegung",
      edmPrice: "EDM Price",
      extraInfo: "Extra Info",
      fullName: "Full Name",
      importantInfo: "Important Info",
      leadTimeDays: "Lead Time Days",
      linkImprint: "Link Imprint",
      linkPrivacy: "Link Privacy",
      linkRecourse: "Link Recourse",
      linkTermsConditions: "Link AGB",
      linkContainerCatalogue: "Link Container Catalog",
      linkTrainingMaterial: "Link Training Material",
      multiMediaCredits: "MultiMedia Credits",
      oppeningHours: "Öffnungszeiten",
      pressRepresentativePhoneNumber: "Press Representative PhoneNumber",
      pressRepresentativeEmail: "Press Representative Email",
      pressRepresentativeName: "Press Representative Name",
      taxPercentage: "Tax Percentage",
      taxesRegistry: "Taxes Registry",
      type: "type",
      webSite: "WebSite",
      kpURL: "KundenPortal URL",
      AuthorizationFlags: "Kunden Portal Authorization Flags",
      EmptyingForm: "EmptyingForm",
      WeightOverviewPass: "WeightOverviewPass",
      PriceOverview: "PriceOverview",
      RecyclingFootprint: "RecyclingFootprint",
      ShowContainerAmount: "ShowContainerAmount",
      ShowNews: "ShowNews",
      ShowSustainabilityInfo: "ShowSustainabilityInfo",
      ShowEmailOptionsAccount: "ShowEmailOptionsAccount",
      ShowEmailOptionsLocations: "ShowEmailOptionsLocations",
      ShowTableWeightNote: "ShowTableWeightNote",
      ShowTableImplementationConfirmation: "ShowTableImplementationConfirmation",
      ShowTableOrderPhoto: "ShowTableOrderPhoto",
      EmailForwardActionTo: "Kunden Portal Emails Forward Action To",
      Container_Action: "Container Action",
      New_Container: "New Container",
      Bussiness_Client_Email_Settings_Loacker: "Bussiness Client Settings Loacker",
      Location_Email_Settings_Loacker: "Location Settings Loacker",
      Container_Complaint: "Container Complaint",
      Cancel_Order: "Cancel Order",
      New_Location: "New Location",
      Delete_Location: "Delete Location",
      KPHolidaysInfos: "Kunden Portal Holiday ",
      HolidayText: "Holiday Text",
      HolidayInfo: "Holiday Info",
      KPLoginInfos: "Kunden Portal Login Infos",
      KPContactInfos: "Kunden Portal Contact Infos",
      kpEmailSender: "Kunden Portal Email Sender",
      KPWhiteList: "Kunden Portal WhiteLists ",
      TWNoteWhitelist: "TW Note List",
      TIConfirmationWhitelist: "TI Confirmation list",
      TOPhotoWhitelist: "TO Photo List",
    }
  },
  userrequests: {
    property: {
      partitionKey: "Datum",
      rowKey: "Type",
      companyNumber: "Firmen N",
      description: "Bemerkung",
      city: "Arbeitsort",
      isActive: "Ist Erledigt durch IT",
      emailSignature: "Email Signatur",
      countryCode: "Landesvorwahl",
      company: "Firma",
      type: "Art",
      priority: "Priorität",
      status: "Status",
      name: "Vorname",
      mittleName: "Mittelname",
      lastName: "Nachname",
      dateEntry: "Datum Eintritt",
      dateExit: "Datum Austritt",
      workPlaceDismantle: "Arbeitsplatz abbauen",
      userTransferDataTo: "Wer Ubernimmt die Netzlaufwekrdaten?",
      userFordwardEmailTo: "Wohin wird die Mail-Adresse umgeleitet?",
      daysToKeepData: "Wie lange bleibt die Mail-Adresse bestehen?",
      manager: "Email der Führungskraft",
      kostenstelle: "Kostenstelle",
      jobTitle: "Stellentitel",
      department: "Abteilung",
      sharedEmail: "emai-Verteiler, Komma getrennt",
      extraInfo: "Sonstige Anforderungen",
      autoScript: "AutoScript",
      userReference: "Gleiche Berrechtigun wie",
      workPlace: "Arbeitsplatz Vorhanden",
      hardware: "Hardware",
      mac: "Mac",
      pc: "PC",
      laptop: "Laptop",
      software: "Software",
      recy: "RECY",
      sap: "SAP",
      office: "Office",
      rona: "Rona",
      cloudapps: "Cloudapps",
      accessories: "Zubehör",
      telephone: "Festnetz-Telefon",
      headset: "Headset",
      mobil: "Mobil-Telefon mit Postfachzugriff",
      camera: "Digital-Kamera",
      ipad: "Ipad",
      tablet: "Android Tablet"
    }
  },
  commodities_view: {
    heading: "Sorte Info",
    text: "Sorte Display",
    writeText: "Sucht ein"
  },
  catalog: {
    property: {
      partitionKey: "PartitionKey",
      rowKey: "RowKey",
      creator: "Creator",
      modifier: "Modifikator",
    },
    success: {
      created: "Element erfolgreich angelegt",
      edited: "Element erfolgreich bearbeitet",
      deleted: "Element erfolgreich gelöscht",
    },
    errors: {
      could_not_load: "Element konnten nicht geladen werden",
      could_not_update: "Element konnte nicht aktualisiert werden",
      could_not_create: "Element konnte nicht erstellt werden",
      could_not_delete: "Element konnte nicht erstellt werden",
    },
    actions: {
      delete: "Löschen",
      activate: "Aktivieren",
      deactivate: "Deaktivieren",
      recordCreate: "Neu",
      recordEdit: "Bearbeiten",
      recordDelete: "Löschen",
      selectAll: "Alle auswählen",
    },
    delete_dialog: {
      heading: "Löschen",
      text: "Möchten Sie dieses Element löschen?",
      confirm_text: "Löschen",
    },
    edit_dialog: {
      edit_heading: "Element bearbeiten",
      create_heading: "Element erstellen",
    },
  }
  , user_role: {
    UNDEFINED: "UNDEFINED",
    SuperValue: "SuperValue",
    ADMIN: "Admin",
    CUSTOMER_SERVICE: "Kundencenter User",
    SUPER_BUSINESS_CLIENT: "Super User",
    BUSINESS_CLIENT: "Bearbeiter",
  },
};
