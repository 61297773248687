import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { de } from "../translations/de";
import LngDetector from "i18next-browser-languagedetector";

export const I18N_LOCAL_STORAGE = "i18nextLng";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LngDetector)
  .init({
    resources: {
      de: {
        translation: de
      }
    },
    fallbackLng: "de",
    debug: false,
    react: {
      transSupportBasicHtmlNodes: true
    },
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag", "path", "subdomain"],
      lookupLocalStorage: I18N_LOCAL_STORAGE
    }
  });

export default i18n;
