import React, {
  FunctionComponent,
  createContext,
  useState,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContext";
import {
  useSnackbar,
  SnackbarSeverity,
} from "../../../context/SnackbarContext";

import { apiConfig } from "../../../services/authConfig";
import { LoackerService } from "../../../services/service";

export const EMPTY_SELECTION = "-1";

interface ILocationManagementContext {
  loading: boolean;
  fetchEquipments: () => Promise<boolean>;
  listEquipments: [];
}

export const LocationManagementContext =
  createContext<ILocationManagementContext>({
    loading: false,
    listEquipments: [],
    fetchEquipments: () => new Promise((resolve, reject) => reject()),
  });

interface ISidebarContextProviderProps {
  children?: React.ReactNode;
}

export const LocationManagementContextProvider: FunctionComponent<
  ISidebarContextProviderProps
> = (props) => {
  const { businessClient } = useContext(AppContext);
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [listEquipments, setListEquipments] = useState<[]>([]);

  const fetchEquipments = async (): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      await getEquipments();

      resolve(true);
    });
  };

  const getEquipments = async () => {
    console.log("getEquipments...");

    setLoading(true);

    fetch("https://localhost:7005/MonitorAppStatus/ReLoadConfiguration", {
      method: "GET",
      mode: "cors",
    })
      .then(async (response) => {
        if (response && response.status !== 404) {
          const result = await response.json();

          const data = JSON.parse(result);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        showSnackbar(
          t("catalog.errors.could_not_load"),
          SnackbarSeverity.ERROR
        );
      });
  };

  return (
    <LocationManagementContext.Provider
      value={{
        fetchEquipments,
        listEquipments,
        loading,
      }}
    >
      {props.children}
    </LocationManagementContext.Provider>
  );
};
