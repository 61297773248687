import React, {
    FunctionComponent,
    createContext,
    useState,
    useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContext";
import {
    useSnackbar,
    SnackbarSeverity,
} from "../../../context/SnackbarContext";

import { apiConfig } from "../../../services/authConfig";
import { LoackerService } from "../../../services/service";
import {
    DigidoTransactionResponseData,
    createDigidoTransactionResponseEntity,
} from "../transactionResponse";
import { ExchangeViewData } from "../taskrequests";
import { centralGatewayAPIUri } from "../../../config";

export const EMPTY_SELECTION = "-1";

interface ILocationManagementContext {
    loading: boolean;
    error: string;
    progress: number;
    totalRecords: number;

    sendOrders: (entity: ExchangeViewData) => Promise<boolean>;
    listEquipments: [];
    transactionResults: DigidoTransactionResponseData[];
}

export const DigidoManagementContext =
    createContext<ILocationManagementContext>({
        loading: false,
        error: "",
        progress: 0,
        totalRecords: 0,
        listEquipments: [],
        transactionResults: [],
        sendOrders: (entity: ExchangeViewData) =>
            new Promise((resolve, reject) => reject()),
    });

interface ISidebarContextProviderProps {
    children?: React.ReactNode;
}

export const DigidoManagementContextProvider: FunctionComponent<
    ISidebarContextProviderProps
> = (props) => {
    const { businessClient } = useContext(AppContext);
    const { showSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);

    const [error, setError] = useState<string>("");

    const [progress, setProgress] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState<number>(0);

    const [listEquipments, setListEquipments] = useState<[]>([]);
    const [transactionResults, setTransactionResults] = useState<
        DigidoTransactionResponseData[]
    >([]);



    const sendOrders = async (argData: ExchangeViewData): Promise<boolean> => {
        console.log("sendOrder...");
        let sucess: boolean = false;

        setLoading(true);

        const orders = argData.bodyMessage;
        const totalR =(argData.bodyMessage.length);
        setTotalRecords(totalR);
        const tempTransactions = [...transactionResults];

        try {
            await Promise.all(
                orders.map(async (record: any, index) => {
        

                    console.log("index not null");

                    var json_arr = JSON.stringify(record);

                    argData.excellInfo = json_arr;

                    await fetch(`${apiConfig.centralGatewayAPIUri}digido`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        mode: "cors",
                        body: JSON.stringify(argData),
                    }).then(async (response) => {

                        if (response && response.status !== 404) {

                            const data = await response.json();
                             
                            const entities = data.map((entity: any) => {
                                return createDigidoTransactionResponseEntity(entity);
                            });
                            tempTransactions.push(...entities);

                            setTransactionResults(tempTransactions);

                            sucess = true;
                        } else {
                            sucess = false;
                        }
                    }).catch((err) => {
                        setLoading(false);                    
                        sucess = false;
                    });


                    const progress = (tempTransactions.length * 100) / totalR;
                    setProgress(progress);


                }))

        } catch (err: any) {
            setLoading(false);
            setError(err);
            showSnackbar(
                t("catalog.errors.could_not_create"),
                SnackbarSeverity.ERROR
            );
            sucess = false;
        }


        setLoading(false);

        return sucess;
    };

    return (
        <DigidoManagementContext.Provider
            value={{
                sendOrders,
                listEquipments,
                transactionResults,
                loading,
                error,
                progress,
                totalRecords,
            }}
        >
            {props.children}
        </DigidoManagementContext.Provider>
    );
};
