import { showMobileView } from "../../../App";
import React, { FunctionComponent, createContext, useState } from "react";

interface ISidebarContext {
  sidebarOpen: boolean;
  toggleSidebar: () => void;
}

export const SidebarContext = createContext<ISidebarContext>({
  sidebarOpen: true,
  toggleSidebar: () => {},
});

interface ISidebarContextProviderProps {
  children?: React.ReactNode;
}

export const SidebarContextProvider: FunctionComponent<
  ISidebarContextProviderProps
> = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(!showMobileView);

  const toggleSidebar = () => {
    console.log(`status sidebar ${sidebarOpen}`);

    setSidebarOpen(!sidebarOpen);
  };

  return (
    <SidebarContext.Provider
      value={{
        sidebarOpen,
        toggleSidebar,
      }}
    >
      {props.children}
    </SidebarContext.Provider>
  );
};
