import { Entity, ITableEntity } from "./Entity";



export type IExchangeView = {
    senderIdentifier: string;
    userName: string;
    password: string;
    address: string;
    intermediateParty: string;
    bodyMessage: string[];
    abfallart: string;
    transportArt: string;
    moduleId: string;
    comment: string;
    movementCode: string;
    loadingLocation: string;
    takeOverMovementCode: string;
    unloadingLocation: string;
    handOverParty: string;
    modal: boolean;
    excellInfo: string;
};

export interface DigidoTransactionRequest {
    date: string;
    senderIdentifier: string;
    address: string;
    userName: string;
    password: string;
    bodyMessage: ArrayBuffer;
    transactionId: string;
    actionType: number;
    intermediateParty: string;
    abfallArt: string;
    transportArt: string;
    moduleId: string;
}
 

export class ExchangeViewEntity extends Entity implements IExchangeView {
    __typename: string = "ExchangeViewEntity";
    senderIdentifier= "9008391777335";
    address= "https://soap.digido.at/v15/";
    userName= "u9008391777335";
    password= "LoackerVerbindung1!";
    bodyMessage= [""];
    intermediateParty= "9008390475171";
    abfallart= "910";
    transportArt= "9008390100059";
    moduleId= "Module1";
    comment= "";
    movementCode= "9008390101643";
    loadingLocation= "9008391785828";
    takeOverMovementCode= "9008390101544";
    unloadingLocation= "9008391785996";
    handOverParty= "9008391777335";
    modal= false;
    excellInfo="";

    constructor(init?: Partial<ExchangeViewEntity>) {
    super();
    Object.assign(this, init);
  }
}

export const createExchangeViewEntity = (entity: any): ExchangeViewEntity => {
    return new ExchangeViewEntity({
    partitionKey: entity.PartitionKey,
    rowKey: entity.RowKey,
    created: entity.Created,
    timestamp: entity.Timestamp,
    creator: entity.Creator,
    modifier: entity.Modifier,
        isActive: entity.IsActive,

        senderIdentifier: "9008391777335",
        address: "https://soap.digido.at/v15/",
        userName: "u9008391777335",
        password: "LoackerVerbindung1!",
        bodyMessage: [""],
        intermediateParty: "9008390475171",
        abfallart: "910",
        transportArt: "9008390100059",
        moduleId: "Module1",
        comment: "",
        movementCode: "9008390101643",
        loadingLocation: "9008391785828",
        takeOverMovementCode: "9008390101544",
        unloadingLocation: "9008391785996",
        handOverParty: "9008391777335",
        modal: false,
        excellInfo: ""


  });
};


export type ExchangeViewData = IExchangeView & ITableEntity;