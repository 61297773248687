import * as React from "react";
import { useContext, useEffect, useState } from "react";
import {
  Drawer,
  Grid,
  Icon,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Theme,
  Fade,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import { DRAWER_WIDTH } from "../../styles/theme";
import Menu from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { LoackerService } from "../../services/service";
import { UserRole } from "../../models/types/graphql-global-types";
import { useLocation, useNavigate } from "react-router-dom";

import { LogoWhite } from "../../assets/custom-icons";

// import { Footer } from "../footer/footer";
import { IMenuItem, menuItems } from "./menu-items";
import { makeStyles } from "tss-react/mui";
import { SidebarContext } from "./context/sidebar-context";
const drawerWidth = 240;

const useStyles = makeStyles()((theme: Theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(9),
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0 4px 16px 0 rgba(100, 101, 103, 0.5)",
  },
  logoContainer: {
    height: 80,
    marginTop: 20,
    marginBottom: 20,
  },
  menuItem: {
    paddingLeft: "13.5px",
    color: "white",
    marginBottom: "10px",
  },
  menuItemText: {
    color: "white !important",
    fontSize: "13.5px",
  },

  menuItemTextSelected: {
    color: "white !important",
    fontSize: "13.5px",
  },
  menuItemTextRoot: {
    padding: 0,
  },
  listItemIcon: {
    color: "white",
    height: 18,
    width: 18,
  },
  listItemIconRoot: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  listItemIconRootCollapsed: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: "100%",
    marginRight: 10,
  },
  listItemRoot: {
    wdith: "100%",
    height: 48,
    marginTop: 5,
    marginBottom: 5,
    paddingTop: 5,
    paddingBottom: 5,
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.25)",
    },
    "&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.25)",
    },
  },
  selected: {
    backgroundColor: "rgba(255, 255, 255, 0.25) !important",
  },
  iconContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    height: "100%",
    width: "80%",
    altText: "Loacker Kundenportal",
  },
}));

interface ISidebarProps {
  showSnackBar: Function;
}

export const Sidebar: React.FunctionComponent<ISidebarProps> = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { toggleSidebar, sidebarOpen } = useContext(SidebarContext);
  const location = useLocation();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { showSnackBar } = props;

  console.log("Sidebar");

  const onMenuItemClick = (item: IMenuItem) => {
    console.log("onMenuItemClick?");
    console.log(item);

    if (item.route) {
      navigate(item.route);
    } else if (!item.route) {
      showSnackBar("Coming soon");
    }
  };

  const selectedMenuItem = menuItems.find((item) =>
    location.pathname.includes(item.route || "---")
  );

  const isItemSelected = (item: IMenuItem): boolean => {
    return selectedMenuItem ? selectedMenuItem.id === item.id : false;
  };

  const userRole = LoackerService.getUserRoles();

  const roles = userRole ? userRole : [];

  const renderMenu = () => {
    return (
      <List>
        {menuItems
          .filter((m) =>
            m.roles
              ? m.roles.some((allowedRole) => roles.includes(allowedRole))
              : true
          )
          .map((item, index) => (
            <MenuItem
              key={index}
              disableGutters={true}
              classes={{
                root: classes.listItemRoot,
                selected: classes.selected,
              }}
              onClick={() => onMenuItemClick(item)}
              selected={isItemSelected(item)}
            >
              <ListItemIcon
                classes={{
                  root: classNames(classes.listItemIconRoot, {
                    [classes.listItemIconRootCollapsed]: !sidebarOpen,
                  }),
                }}
              >
                <Icon className={classes.iconContainer}>{item.icon}</Icon>
              </ListItemIcon>
              {sidebarOpen && (
                <ListItemText
                  primary={t(item.title)}
                  classes={{
                    primary: isItemSelected(item)
                      ? classes.menuItemTextSelected
                      : classes.menuItemText,
                    root: classes.menuItemTextRoot,
                  }}
                />
              )}
            </MenuItem>
          ))}
      </List>
    );
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  useEffect(() => {
    const resize = () => {
      let newOpenState = window.innerWidth > 992;
      // toggleSidebar();
    };
    //will mount
    window.addEventListener("resize", resize);
    //will unmount
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  return (
    <Drawer
      // sx={{
      //   width: drawerWidth,
      //   flexShrink: 0,
      //   "& .MuiDrawer-paper": {
      //     width: drawerWidth,
      //     boxSizing: "border-box",
      //   },
      // }}
      variant={"permanent"}
      anchor={"left"}
      open={sidebarOpen}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: sidebarOpen,
        [classes.drawerClose]: !sidebarOpen,
      })}
      classes={{
        paper: classNames([
          classes.drawerPaper,
          {
            [classes.drawerOpen]: sidebarOpen,
            [classes.drawerClose]: !sidebarOpen,
          },
        ]),
      }}
    >
      <div>
        <Grid container justifyContent={sidebarOpen ? "flex-end" : "center"}>
          <IconButton
            onClick={() => toggleSidebar()}
            style={{ color: "white" }}
          >
            {sidebarOpen ? <ChevronLeftIcon /> : <Menu />}
          </IconButton>
        </Grid>
      </div>

      {/* <DrawerHeader>
        <IconButton onClick={toggleSidebar}>
          {sidebarOpen ? <ChevronLeftIcon /> : <Menu />}
        </IconButton>
      </DrawerHeader> */}

      <Grid
        container
        direction="column"
        // justifyContent={open ? "flex-end" : "center"}
        style={{ height: "100%" }}
      >
        <Grid item xs>
          <Grid container alignContent={"stretch"} direction={"row"}>
            <Grid
              item
              container
              className={classes.logoContainer}
              xs={12}
              justifyContent={"center"}
            >
              <LogoWhite className={classes.logo} />
            </Grid>
            <Grid item={true} xs={12}>
              {renderMenu()}
            </Grid>
          </Grid>
        </Grid>
        <Fade in={sidebarOpen}>
          <Grid item>{/* <Footer /> */}</Grid>
        </Fade>
      </Grid>
    </Drawer>
  );
};
