import React, { useState } from "react";
import { Theme, createStyles, lighten } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, Paper, Container } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import "./Tamagotchi.css";
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
  },
  container: {
    width: "100%",
    position: "relative",
  },
  card: {},
  table: {
    padding: theme.spacing(2),
    width: "100%",
    marginTop: "20px",
    "& img": {
      width: "100%",
    },
  },
  diagram: {
    marginTop: 50,
    padding: 30,
  },
  img: {
    widh: "250px",
    borderRadius: "8px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

interface IEventsPageProps {}
interface IBar {
  value: number;
}
export const GamesPage: React.FunctionComponent<IEventsPageProps> = (props) => {
  const { classes } = useStyles();
  const [markdown, setMarkdown] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [name, setName] = React.useState("Tamagochi");

  const [hunger, setHunger] = useState(100);
  const [happiness, setHappiness] = useState(100);
  const [energy, setEnergy] = useState(100);
  const [health, setHealth] = useState(100);
  const [isAlive, setIsAlive] = useState(true);
  const [age, setAge] = useState(1);
  const [runnning, setRunngin] = useState(false);
  const [ageGroup, setAgeGroup] = useState("baby");

  React.useEffect(() => {
    // const interval = setInterval(() => {
    //   setIsAlive(energy > 5 ? true : false);
    //   setEnergy((energy) => Math.max(0, energy - 1));
    //   setHappiness((happiness) => Math.max(0, happiness - 1));
    //   setHunger((hunger) => Math.max(0, hunger - 1));
    // }, 10000);

    // return () => clearInterval(interval);
    console.log("create intervals");
    if (runnning === false) {
      const intervalId = setInterval(() => {
        updateHealth();
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
    if (age < 5) {
      setAgeGroup("baby");
    } else if (age > 5 && age < 10) {
      setAgeGroup("toddler");
    } else if (age > 10 && age < 18) {
      setAgeGroup("teenager");
    } else if (age < 30) {
      setAgeGroup("adult");
    } else {
      setAgeGroup("older");
    }
  }, [age, ageGroup]);

  const feedTamagotchi = () => {
    setHunger((prevHunger) => Math.max(prevHunger - 10, 0));
    setHappiness((prevHappiness) => prevHappiness + 5);
    setHealth((prevHealth) => prevHealth + 5);
  };
  const playWithTamagotchi = () => {
    setEnergy((prevEnergy) => Math.max(prevEnergy - 10, 0));
    setHappiness((prevHappiness) => prevHappiness + 10);
    setHealth((prevHealth) => prevHealth + 5);
  };

  function updateHealth() {
    setAge((age) => age + 1);
    setEnergy((energy) => Math.max(0, energy - 1));
    setHappiness((happiness) => Math.max(0, happiness - 1));
    setHunger((hunger) => Math.max(0, hunger - 1));

    const newHealth = Math.floor((hunger + happiness + energy) / 3);
    setHealth(newHealth);
    if (newHealth < 20) {
      setIsAlive(false);
    }
  }
  function HealthBar(props: IBar) {
    const { value } = props;

    const color = value > 80 ? "green" : value > 20 ? "yellow" : "red";
    return (
      <>
        <span className="health-text">Health</span>
        <div className="health-bar progress-bar">
          <div className={`health ${color}`} style={{ width: `${value}%` }}>
            <span>{value}%</span>
          </div>
        </div>
      </>
    );
  }

  function HappinessBar(props: IBar) {
    const { value } = props;
    const color = value > 80 ? "green" : value > 20 ? "yellow" : "red";
    return (
      <>
        <span className="happiness-text">Happiness</span>
        <div className="happiness-bar progress-bar">
          <div className={`happiness ${color}`} style={{ width: `${value}%` }}>
            <span>{value}%</span>
          </div>
        </div>
      </>
    );
  }

  return (
    <Paper className={classes.table}>
      {loading && <CircularProgress color="primary" />}
      <div>
        <h1>Tamagotchi</h1>
        <img
          className={`tamagotchi-image tamagotchi-age-${ageGroup}`}
          alt="Tamagotchi"
        />
        <p>Hunger: {hunger}</p>
        <p>Happiness: {happiness}</p>
        <p>Energy: {energy}</p>
        <div className="age">{age} years old</div>

        <HealthBar value={health} />
        <HappinessBar value={happiness} />

        <p>Status: {isAlive ? "Alive" : "Dead"}</p>
      </div>
      <div className="buttons">
        <button className="button" onClick={feedTamagotchi}>
          Feed
        </button>
        <button className="button" onClick={playWithTamagotchi}>
          Play
        </button>
      </div>
    </Paper>
  );
};
