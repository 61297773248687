import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from "react";
import {
  Button,
  Grid,
  Link,
  TextField,
  Theme,
  useTheme,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import emailValidator from "email-validator";
import { LoginResult } from "../models/login";
import { Trans, useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { LoackerService } from "../services/service";
import lodash, { isNull } from "lodash";
import { LoginVariables, LoginI } from "../models/types/Login";
import { Logo } from "../assets/custom-icons";
import { useSnackbar, SnackbarSeverity } from "../context/SnackbarContext";
import { LoginCard } from "../components/core/layouts/login-card";
import { Background } from "../components/core/layouts/background";
import { AppContext } from "../context/AppContext";
import { showMobileView } from "../App";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
  },
  wrapper: {},

  component: {
    display: "flex",
    alignItems: "center",
  },

  textInputComponent: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
  },

  logo: {
    width: 250,
    height: 200,
    altText: "Loacker Kundenportal",
  },

  alert: {
    height: 48,
    alignItems: "center",
  },
  passwordReset: {
    marginTop: 5,
    fontSize: 12,
    cursor: "pointer",
  },
  loginErrorText: {
    color: theme.palette.error.main,
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 20,
  },
  checkboxContainer: {
    width: showMobileView ? "100%" : 414,
    marginTop: 16,
  },
  checkbox: {
    color: "white",
  },
  checkboxLabel: {
    color: "white",
    margin: 0,
  },
  link: {
    color: "white",
  },
}));

interface ILoginProps {}

export const Login: FunctionComponent<ILoginProps> = (props) => {
  const [loginData, setLoginData] = useState<LoginI>({} as LoginI);
  const [loading, setLoading] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const { toggleBusinessClientDialog } = useContext(AppContext);

  const handleLoginButtonClick = async () => {
    setLoading(true);
    console.log("handleLoginButtonClick");
    try {
      const response = await LoackerService.onSignIn(false);

      console.log("Response in handleLoginButtonClick");
      console.log(response);
      if (response === "OK") {
        setLoading(false);
        showSnackbar("Willkommen");
        navigate("/portal/catalog");
      } else {
        setLoading(false);
        showSnackbar(`${response}`, SnackbarSeverity.ERROR);
      }
    } catch (err) {
      setLoading(false);
      showSnackbar(`${err}`, SnackbarSeverity.ERROR);
    }
  };

  useEffect(() => {
    console.log("useEffectLogin");

    if (LoackerService.isLoggedIn()) {
      navigate("/portal/catalog");
    }
  }, [navigate]);

  const handlePasswordForget = () => {
    navigate("/password-reset");
  };

  return (
    <Background
      container
      component="form"
      justifyContent="center"
      onSubmit={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        handleLoginButtonClick();
      }}
    >
      <Grid item xs={12} className={classes.wrapper}>
        <Grid
          container
          justifyContent="center"
          direction={"row"}
          alignContent={"center"}
          alignItems="center"
        >
          <Grid item xs={12} container justifyContent="center">
            <LoginCard>
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
                alignContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="center"
                    className={classes.component}
                  >
                    <Logo className={classes.logo} />
                  </Grid>
                </Grid>
                <Grid item xs={12} container className={classes.component}>
                  <Button
                    disabled={loading}
                    variant="contained"
                    onClick={handleLoginButtonClick}
                    type={"submit"}
                    color="primary"
                    fullWidth
                  >
                    {loading ? (
                      <ClipLoader
                        color={theme.palette.primary.main}
                        size={17}
                      />
                    ) : (
                      t("login")
                    )}
                  </Button>
                </Grid>
              </Grid>
            </LoginCard>
          </Grid>
        </Grid>
      </Grid>
    </Background>
  );
};
