import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
} from "react";
import { Snackbar, Theme } from "@mui/material";
import { Alert, AlertProps } from "@mui/material";
import {
  INFO_COLOR,
  ERROR_COLOR,
  SUCCESS_COLOR,
  WARNING_COLOR,
} from "../styles/theme";
import Slide, { SlideProps } from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Fade from "@mui/material/Fade";

import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    minWidth: 300,
  },
  info: {
    backgroundColor: INFO_COLOR,
  },
  error: {
    backgroundColor: ERROR_COLOR,
  },
  success: {
    backgroundColor: SUCCESS_COLOR,
  },
  warning: {
    backgroundColor: WARNING_COLOR,
  },
}));

interface ISnackbarContext {
  showSnackbar: (
    message: string,
    severity?: SnackbarSeverity,
    autohide?: boolean
  ) => void;
}

const SnackbarContext = createContext<ISnackbarContext>({
  showSnackbar: () => null,
});

interface ISnackbarMessage {
  message: string;
  severity: AlertProps["severity"];
  autohide: boolean;
}

export enum SnackbarSeverity {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

interface IToolbarProps {
  children?: React.ReactNode;
}
function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}
export const SnackbarProvider: FunctionComponent<IToolbarProps> = (props) => {
  const { classes } = useStyles();
  const queueRef = React.useRef<ISnackbarMessage[]>([]);
  const [snackbarMessage, setSnackbarMessage] =
    useState<ISnackbarMessage | null>(null);
  const [shouldShowSnackbar, setShouldShowSnackbar] = useState<boolean>(false);

  const [state, setState] = React.useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;
      }
    >;
  }>({
    open: false,
    Transition: Fade,
  });

  const onShowSnackbar = (
    message: string,
    severity: SnackbarSeverity = SnackbarSeverity.INFO,
    autohide: boolean = true
  ) => {
    queueRef.current.push({
      message,
      severity,
      autohide,
    });

    if (shouldShowSnackbar) {
      setShouldShowSnackbar(false);
    } else {
      processQueue();
    }
  };

  const onClose = () => {
    setShouldShowSnackbar(false);
  };

  const processQueue = () => {
    const nextSnackbarMessage = queueRef.current.shift();
    if (nextSnackbarMessage) {
      setSnackbarMessage(nextSnackbarMessage);
      setShouldShowSnackbar(true);
    }
  };

  const handleExited = () => {
    processQueue();
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar: onShowSnackbar }}>
      <Snackbar
        open={shouldShowSnackbar}
        autoHideDuration={snackbarMessage?.autohide === false ? null : 3000}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        TransitionComponent={state.Transition}
      >
        <Alert
          onClose={onClose}
          severity={snackbarMessage?.severity}
          variant="filled"
          classes={{
            root: classes.root,
            filledInfo: classes.info,
            filledSuccess: classes.success,
            filledWarning: classes.warning,
          }}
        >
          {snackbarMessage?.message}
        </Alert>
      </Snackbar>
      {props.children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);
