import React, { FunctionComponent } from "react";
import { Theme,  DialogContent, DialogContentProps } from "@mui/material";
import { makeStyles } from "tss-react/mui"; const useStyles = makeStyles()((theme: Theme) => ({
  dialogContent: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  }
}));

interface IResponsiveDialogContentProps extends DialogContentProps {}

export const ResponsiveDialogContent: FunctionComponent<IResponsiveDialogContentProps> = props => {
  const { children, className, ...rest } = props;
  const { classes }= useStyles();
  const cn = className ? `${classes.dialogContent} ${className}` : classes.dialogContent;
  return (
    <DialogContent {...rest} className={cn}>
      {children}
    </DialogContent>
  );
};
