import React, { FunctionComponent } from "react";
import { Theme, GridProps, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  background: {
    backgroundColor: theme.palette.primary.main,
    flex: 1,
    padding: 16,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
}));

interface IBackgroundProps extends GridProps {
    component:  React.ElementType;
}

export const Background: FunctionComponent<IBackgroundProps> = (props) => {
  const { classes } = useStyles();
  const { className, children, ...rest } = props;
  const cn = className
    ? `${className} ${classes.background}`
    : classes.background;
  return (
    <Grid className={cn} {...rest}>
      {children}
    </Grid>
  );
};
