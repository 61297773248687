import * as React from "react";
import { withTranslation } from "react-i18next";
import { Theme, Typography } from "@mui/material";
import { IBaseProps } from "../../../utils/BaseProps";
import { makeStyles, withStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  titleText: {
    fontSize: 18,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 0.75,
    letterSpacing: "-0.2px",
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: 16,
  },
}));
interface IHeaderProps {
  title: string;
}

export const Subheading: React.FunctionComponent<IHeaderProps> = (props) => {
  const { ...rest } = props;
  const { classes } = useStyles();
  return <Typography className={classes.titleText}>{props.title}</Typography>;
};
