import React from "react";

interface ScoreboardProps {
  score: number;
  onResetGameClick: () => void;
  emoticon: string;
}

const Scoreboard: React.FC<ScoreboardProps> = ({
  score,
  onResetGameClick,
  emoticon,
}) => {
  return (
    <div className="scoreboard">
      <p className="score">Ihre Punktzahl: {score}</p>
      <p className="emoticon">{emoticon}</p>
      <button className="reset" onClick={onResetGameClick}>
        Spiel zurücksetzen
      </button>
    </div>
  );
};

export default Scoreboard;
