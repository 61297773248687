import React, {
  createContext,
  FunctionComponent,
  useState,
  useEffect,
} from "react";
import { BusinessClient, Me } from "../models/types/me";
import { LoackerService } from "../services/service";
import lodash from "lodash";
import { useSnackbar, SnackbarSeverity } from "./SnackbarContext";
import { useTranslation } from "react-i18next";
// import * as signalR from "@microsoft/signalr";
import { OperationProgress } from "../models/ReportProgress";
import { centralGatewayUri } from "../config";
import { connect } from "http2";
import { RestaurantRounded } from "@mui/icons-material";

interface IAppContext {
  businessClients: BusinessClient[];
  businessClientsLoading: boolean;
  businessClient: BusinessClient | undefined;
  refetchBusinessClients: () => void;
  setBusinessClient: (businessClient: BusinessClient | undefined) => void;
  holidays: Date[];
  setHolidays: (holidays: Date[]) => void;
  toggleBusinessClientDialog: () => void;
  // startSignalRConnection: () => void;
  // connection: signalR.HubConnection | undefined;
  // hubConnected: boolean;
  // clientId: string | null;
}

export const AppContext = createContext<IAppContext>({
  businessClients: [],
  businessClientsLoading: false,
  refetchBusinessClients: () => {},
  businessClient: undefined,
  setBusinessClient: (businessClient: BusinessClient | undefined) => {},
  holidays: [],
  setHolidays: (holidays: Date[]) => {},
  toggleBusinessClientDialog: () => {},
  // startSignalRConnection: () => {},
  // connection: {} as signalR.HubConnection,
  // hubConnected: false,
  // clientId: null,
});

interface IToolbarProps {
  children?: React.ReactNode;
}

export const AppContextProvider: FunctionComponent<IToolbarProps> = (props) => {
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [businessClient, setBusinessClient] = useState<
    BusinessClient | undefined
  >(LoackerService.getBusinessClient());
  const [meQueryLoading, setMeQueryLoading] = useState<boolean>(false);
  const [isBusinessClientDialogOpen, setIsBusinessClientDialogOpen] =
    useState<boolean>(false);
  const [holidays, setHolidays] = useState<Date[]>([]);
  // const [hubConnected, setHubConnected] = React.useState(false);
  // const [connection, setConnection] = useState<
  //   signalR.HubConnection | undefined
  // >(undefined);
  // const [clientId, setClientId] = React.useState<string | null>(null);
  // const [appContextEventsAttached, setAttachedEvents] = React.useState(false);

  const useLazyQuery = (argLoginVariables: {}): any[] => {
    return [
      {
        called: true,
        loading: false,
        data: [],
      },
    ];
  };

  const [holidaysQuery] = useLazyQuery({
    onCompleted: (data: any) => setHolidays(data.holidays),
  });

  const useQuery = (argLoginVariables: {}): {
    loading: boolean;
    data: { me: Me };
    refetch: any;
  } => {
    return {
      loading: true,
      data: { me: {} as Me },
      refetch: undefined,
    };
  };

  useEffect(() => {
    console.log("effect AppContext");

    // if (connection === undefined && LoackerService.isLoggedIn() === true) {
    //   setupSignalRConnection();
    // }

    // if (
    //   connection !== undefined &&
    //   (connection.state === signalR.HubConnectionState.Disconnected ||
    //     connection.state !== signalR.HubConnectionState.Connecting)
    // ) {
    //   startSignalRConnection();
    // }

    // if (
    //   connection !== undefined &&
    //   hubConnected == true &&
    //   appContextEventsAttached === false &&
    //   connection.state === signalR.HubConnectionState.Connected
    // ) {
    //   AttachEvents();
    // }
    // }, [appContextEventsAttached, connection, hubConnected]);
  }, []);

  const { data: meQueryData, refetch: refetchBusinessClients } = useQuery({
    skip:
      !LoackerService.getUser() ||
      LoackerService.hasToManuallyEnterAccountNumber(),
    onError: () => {
      showSnackbar(
        t("app.errors.could_not_load_business_clients"),
        SnackbarSeverity.ERROR
      );
    },
  });

  const updateBusinessClient = (businessClient: BusinessClient | undefined) => {
    if (businessClient) {
      LoackerService.setBusinessClient(businessClient);
    } else {
      LoackerService.removeBusinessClient();
    }

    setBusinessClient(businessClient);
  };

  const businessClients = meQueryData?.me.assignedBusinessClients || [];
  const locations = meQueryData?.me.assignedLocations || [];
  const locationsForBusinessClient = locations.filter(
    (loc) => loc.accountId === businessClient?.recyUuid
  );

  // const setupSignalRConnection = async () => {
  //   console.log("Appcontext preparing for signalR...");

  //   if (connection) {
  //     console.log("Appcontext connection already exist ");
  //     return;
  //   }

  //   console.log("Appcontext SetupSignalRConnection");
  //   const token = await LoackerService.acquireCentralGatewayToken();
  //   if (!token) {
  //     return;
  //   }

  //   console.log("Appcontext setConnection");

  //   setConnection(
  //     new signalR.HubConnectionBuilder()
  //       .withUrl(`${centralGatewayUri}notificationshub`, {
  //         accessTokenFactory: () => token,
  //       })
  //       .build()
  //   );
  // };

  // const startSignalRConnection = async () => {
  //   if (connection === undefined) {
  //     console.log("Appcontext connection undefined");
  //     await setupSignalRConnection();
  //     return;
  //   }

  //   if (
  //     connection !== undefined &&
  //     connection.state === signalR.HubConnectionState.Connected
  //   ) {
  //     console.log("Appcontext connectionalready Connected");
  //     return;
  //   }

  //   await connection
  //     ?.start()
  //     .then(() => {
  //       console.log("Appcontext setHubConnected");
  //       setHubConnected(true);
  //       setClientId(connection.connectionId);
  //     })
  //     .catch((error) => {
  //       console.log("Live Socket Connection error");
  //       console.log(error);
  //       setHubConnected(false);
  //       setClientId(null);
  //       showSnackbar("Live Socket Connection error", SnackbarSeverity.ERROR);
  //     });
  // };
  // const AttachEvents = async () => {
  //   if (connection === undefined) {
  //     return;
  //   }

  //   connection.on("SendOperationCompleted", (data: OperationProgress) => {
  //     if (data.isCompleted === true) {
  //       showSnackbar(
  //         `Recy Data ${data.name} was Updated `,
  //         SnackbarSeverity.SUCCESS,
  //         false
  //       );
  //     }
  //     if (data.isCompleted === false) {
  //       showSnackbar(
  //         `Recy Data  ${data.name} Update started `,
  //         SnackbarSeverity.SUCCESS,
  //         false
  //       );
  //     }
  //   });

  //   connection.onclose(() => {
  //     setHubConnected(false);
  //     setAttachedEvents(false);
  //   });

  //   if (connection.state === signalR.HubConnectionState.Connected) {
  //     connection.invoke("SubscribeToBoard", "MDM").catch((err: Error) => {
  //       return console.error(err.toString());
  //     });
  //     setAttachedEvents(true);
  //   }
  // };

  return (
    <AppContext.Provider
      value={{
        businessClients,
        businessClientsLoading: meQueryLoading,
        refetchBusinessClients,
        businessClient,
        // hubConnected,
        // connection,
        // clientId,
        // startSignalRConnection,
        setBusinessClient: updateBusinessClient,
        holidays,
        setHolidays,
        toggleBusinessClientDialog: () =>
          setIsBusinessClientDialogOpen(!isBusinessClientDialogOpen),
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
