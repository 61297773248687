import React from "react";
import BinButton from "./BinButton";
import "./garbage.css";

interface BinListProps {
  onBinClick: (binType: string) => void;
}

const BinListLeft: React.FC<BinListProps> = ({ onBinClick }) => {
  const bins = [
    { type: "kunststoff" },
    { type: "papier" },
    { type: "bio" },
    { type: "glass" },
  ];

  return (
    <div className="garbage-containerList">
      {bins.map((bin) => (
        <BinButton key={bin.type} binType={bin.type} onBinClick={onBinClick} />
      ))}
    </div>
  );
};

export default BinListLeft;
