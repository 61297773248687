import * as React from "react"; 
import { Modal, ModalHeader, ModalBody, ModalFooter,Button} from 'reactstrap'; 
import copy from "copy-to-clipboard";
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import { log } from "util";

interface IModuleProps  {
    data: string
};

export default class ModalContainer extends React.PureComponent<IModuleProps> {
     
    public state = {
        data: '',
        isOpen:false
    };

    private toggle = () => {
        console.log('toggle')
        this.setState({
isOpen: !this.state.isOpen
        });
    }

    componentDidMount() {

        console.log(this.props)
        this.setState({
data: this.props.data,
isOpen: false
        });
    }
    private copyclip = (e: React.MouseEvent, argValue: string) => {

        e.preventDefault();
        copy(argValue);
    } 
    public render() {

    return (
        <div>
<Button onClick={this.toggle} outline color="primary" size="sm">info</Button>
<Modal isOpen={this.state.isOpen} toggle={this.toggle} size="lg" scrollable={true} >
    <ModalHeader toggle={this.toggle}>
        <Button color="secondary" onClick={(e: React.MouseEvent) => this.copyclip(e, this.state.data)}>Copy</Button>
    </ModalHeader>
    <ModalBody>
        <div style={{ overflowWrap: "break-word" }}>
<JSONPretty data={this.state.data}></JSONPretty>
        </div>
    </ModalBody>
    <ModalFooter>

        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
    </ModalFooter>
</Modal>
        </div>
    )
    }
}  