import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";

import { Theme, createStyles, lighten } from "@mui/material/styles";
import { UserRole } from "../models/types/graphql-global-types";
import { LoackerService } from "../services/service";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CatalogPage } from "../components/CatalogPage";
import { SidebarContextProvider } from "../components/sidebar/context/sidebar-context";
import { ToolbarCustom } from "./ToolbarCustom";
import { makeStyles } from "tss-react/mui";
import { showMobileView } from "../App";
import { SidebarMobile } from "../components/sidebar/sidebar-mobile";
import { Sidebar } from "../components/sidebar/Sidebar";
import { useSnackbar } from "../context/SnackbarContext";
import { Arquitecture } from "../components/Arquitecture";
import { CentralGatewayPage } from "../components/CentralGateway";
import { ExternalGatewayPage } from "../components/ExternalGateway";
import { AtgolorsdataapiPage } from "../components/AtgolorsdataapiPage";
import { HandelsappPage } from "../components/Handelsapp";
import { WebsitesAzurePage } from "../components/WebsitesAzure";
import { AuthAzurePage } from "../components/AuthAzure";
import { AppsInfoPage } from "../components/AppsInfo";
import { MonitorPage } from "../components/monitor/MonitorPage";
import { GamesPage } from "../components/Games";
import ButtonsGamePage from "../components/garbageButtons/ButtonsGamePage";
import { DigidoPage } from "../components/Digido/DigidoPage";
import { MyProfilePage } from "../components/my-profile/my-profile-page";

interface IPortalProps {}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flexGrow: 1,
    background: theme.palette.background.default,
    minHeight: `calc(100vh - ${
      showMobileView ? theme.spacing(1) : theme.spacing(2)
    }px)`,
    flexFlow: "column",
    width: showMobileView ? "100%" : "auto",
    display: "flex",
  },
  content: {
    height: "100%",
    display: "flex",
    flex: 1,
    flexFlow: "column",
    marginTop: 0,
    width: "auto",
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export const Portal: FunctionComponent<IPortalProps> = (props) => {
  const [roles, setRoles] = useState<UserRole[]>([]);
  const { classes } = useStyles();
  const { showSnackbar } = useSnackbar();
  console.log("Portal");
  useEffect(() => {
    if (LoackerService.isLoggedIn() === false) {
      return;
    }

    if (roles == undefined || roles.length <= 0) {
      // console.log('getting roles');
      LoackerService.fetchUserProfile().then((response) => {
        const userRoles = LoackerService.getUserRoles();
        if (userRoles) {
          setRoles(userRoles);
        }
      });
    } else {
    }
  }, [roles]);

  return (
    <Fragment>
      <div style={{ display: "flex" }}>
        <SidebarContextProvider>
          {showMobileView ? (
            <SidebarMobile />
          ) : (
            <Sidebar showSnackBar={showSnackbar} />
          )}
          <div className={classes.root}>
            <ToolbarCustom />
            <div className={classes.content}>
              <Routes>
                <Route path="/catalog" element={<CatalogPage />} />
                <Route path="/digido" element={<DigidoPage />} />
                <Route path="/architecture" element={<Arquitecture />} />
                <Route path="/monitor" element={<MonitorPage />} />

                <Route path="/info" element={<AppsInfoPage />} />
                <Route path="/central" element={<CentralGatewayPage />} />
                <Route path="/external" element={<ExternalGatewayPage />} />
                <Route
                  path="/atgolorsdataapi"
                  element={<AtgolorsdataapiPage />}
                />
                <Route path="/handelsapp" element={<HandelsappPage />} />
                <Route path="/azure" element={<WebsitesAzurePage />} />
                <Route path="/auth" element={<AuthAzurePage />} />
                <Route path={`/my-profile`} element={<MyProfilePage />} />
                {/* <Route path="/games" element={<GamesPage />} /> */}
                <Route path="/games" element={<ButtonsGamePage />} />
              </Routes>
            </div>
          </div>
        </SidebarContextProvider>
      </div>
    </Fragment>
  );
};
