
import { AccountInfo } from "@azure/msal-browser";

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};

export const requiresInteraction = (errorMessage: any) => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1
    );
};

 
export type tokenInfo={
    resourceName:string|null,
    expirationDate:Date|null
    token:string
}
export type stateTransfer = {
    isAuthenticated: boolean,
    account: AccountInfo|null,
    homeAccountId:string,
    username:string,
    error: string,
    tokens:tokenInfo[]|null
};
export interface AccountState {
    accountf: AccountInfo,
    error: null,
    idToken: {},
    idTokenClaims: {},
    isAuthenticated: boolean
}

export interface Request {
    name:string,
    scopes: string[],
    account:AccountInfo,     
    forceRefresh: boolean
}
