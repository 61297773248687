import React from "react";
import BinButton from "./BinButton";
import "./garbage.css";

interface BinListProps {
  onBinClick: (binType: string) => void;
}

const BinListRight: React.FC<BinListProps> = ({ onBinClick }) => {
  const bins = [
    { type: "metal" },
    { type: "bauschutt" },
    { type: "restmüll" },
    { type: "sonst" },
  ];

  return (
    <div className="garbage-containerList">
      {bins.map((bin) => (
        <BinButton key={bin.type} binType={bin.type} onBinClick={onBinClick} />
      ))}
    </div>
  );
};

export default BinListRight;
