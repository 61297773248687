import React, { FunctionComponent } from "react";
import { Theme, Dialog, DialogProps, Button } from "@mui/material";
import Slide, { SlideProps } from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2),
    },
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const transition = (props: SlideProps) => {
  return <Slide {...props} direction="left" />;
};

interface IResponsiveDialogProps extends DialogProps {}

interface IFullScreenDialogProps extends DialogProps {
  title: string | undefined;
  onClose(): any;
}
export const ResponsiveDialog: FunctionComponent<IResponsiveDialogProps> = (props) => {
  const { children, ...rest } = props;
  const { classes } = useStyles();

  const paper = classes?.paper ? `${classes.paper} ${classes.paper}` : classes.paper;

  return (
    <Dialog {...rest} classes={{ paper }}>
      {children}
    </Dialog>
  );
};

export const FullScreenDialog: FunctionComponent<IFullScreenDialogProps> = (props) => {
  const { children, title, onClose, ...rest } = props;

  const { classes } = useStyles();

  const paper = classes?.paper ? `${classes.paper} ${classes.paper}` : classes.paper;

  return (
    <Dialog fullScreen {...rest} TransitionComponent={transition} classes={{ paper }}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onClose()} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
};
