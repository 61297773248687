import React from "react";

export interface GarbageItemProps {
  name: string;
  number: string;
  type: string;
}

const GarbageItem: React.FC<GarbageItemProps> = ({ name, number, type }) => {
  return (
    <div className="item">
      <p>{name}</p>
      <p>{number}</p>
      {/* <img src={`/icons/${type}.png`} alt={type} /> */}
    </div>
  );
};

export default GarbageItem;
